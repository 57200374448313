<template>
  <div class="box">
    <div class="teacherBox">
      <img class="teacherHeaderImg" :src="this.teacherInfo ? this.teacherInfo[1].avatar : ''" alt="">
      <div>
        <img class="levelImg" src="../assets/images/teacherJs/level.png" alt="">
        <p>
          <span>{{this.teacherInfo[1] ? this.teacherInfo[1].whoami : 'XX'}}</span>
        </p>
      </div>
    </div>
    <div class="upDown">
      <img @click="controlClick" v-if="control" src="../assets/images/teacherJs/up.png" alt="">
      <img @click="controlClick" v-else src="../assets/images/teacherJs/down.png" alt="">
    </div>
    <div v-if="!control" class="introduce">
      <div class="introduceBox">
        <img src="../assets/images/teacherJs/introduce.png" alt="">
        <p>
          {{this.teacherInfo ? this.teacherInfo[1].intro : ''}}
        </p>
      </div>
      <div class="recordBox">
        <img class="recordBoxLeftImg" src="../assets/images/teacherJs/record.png" alt="">
        <ul>
          <li>
            <span>{{this.teacherInfo ? this.teacherInfo[1].tradecnt : ''}}</span>
            <p>
              <img src="../assets/images/teacherJs/icon1.png" alt="">
              交易笔数
            </p>
          </li>
          <label for=""></label>
          <li>
            <span>{{this.teacherInfo ? this.teacherInfo[1].winrate : ''}}</span>
            <p>
              <img src="../assets/images/teacherJs/icon2.png" alt="">
              交易胜率
            </p>
          </li>
          <label for=""></label>
          <li>
            <span>{{this.teacherInfo ? this.teacherInfo[1].gainrate : ''}}</span>
            <p>
              <img src="../assets/images/teacherJs/icon3.png" alt="">
              交易回报率
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="simple" @click="showDialog">
      {{this.bnotes ? timestampToTime(this.bnotes[0].ut) : ''}}&nbsp;{{this.teacherInfo ? this.teacherInfo[1].whoami : ''}}老师：&nbsp;{{this.bnotes ? this.getNewName(this.bnotes[0].info) : ''}}
    </div>
    <el-dialog title="" :visible.sync="teacher.visible" :width="teacher.visibleWidth">
      <div class="ssDialog" v-html="this.bnotes ? this.bnotes[0].info : ''">
      </div>
    </el-dialog>
    <!-- <button class="room-change-btn" @click.stop="handleRoomList" /> -->
  </div>
</template>

<script>
export default {
  name:'teacherIntroduce',
  data(){
    return{
      control:false,
      teacher:{
        time:'16:09',
        visible:false,
        visibleWidth:'800px',
      }
    }
  },
  computed:{
    // craig--newAdd
    teacherInfo(){
      return this.$store.state.home.teacherInfo
    },
    bnotes(){
      return this.$store.state.home.bnotes
    },
    roomInfo() {
      return this.$store.state.home.roomInfo
    },
  },
  created(){
  },
  methods:{
    // craig--获取课堂笔记列表
    async getBnotes(){
      const option = {
        broomid:this.roomInfo.id,
        tid:this.teacherInfo && this.teacherInfo[1].id,
        bsheetid:this.teacherInfo && this.teacherInfo[0].value.id,
        sort:'ut,DESC',
      }
      await this.$store.dispatch('home/getBnotes',option)
    },
    // 控制讲师简介的显示
    controlClick(){
      this.control = !this.control;
      // 初始化课堂笔记
      this.getBnotes();
    },
    // 
    showDialog(){
      this.teacher.visible = true;
    },
    // 处理只显示十个字符，其他的用...代替
    getNewName(name) {
      var len = 0;
      var reallen = 0;
      for (var j=0; j<name.length; j++) {
        if (name.charCodeAt(j)>127 || name.charCodeAt(j)==94) {
          len += 1;
        } else {
          len ++;
        }
        if(len<11){
          reallen++;
        }
      }
      if (len > 10) {
        name = name.substr(0,reallen)+'···';
      }
      return name;
    },
    // 时间戳转时间格式
    timestampToTime(timestamp) {
      let date = new Date(parseInt(timestamp));
      let Year = date.getFullYear();
      let Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
      let Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
      let Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
      let Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
      let Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      return Hour +':'+ Minute;
    }
  }
}
</script>

<style lang="less" scoped>
.box{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: normal;
  box-sizing: border-box;
  padding: 0 10px;
  .teacherBox{
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .teacherHeaderImg{
      width: 60px;
      height: 60px;
      margin-right: 8px;
      border-radius: 8px;
    }
    div{
      .levelImg{
        width: 40px;
        margin-bottom: 10px;
      }
      p{
        display: flex;
        justify-content: center;
        align-items: center;
        span{
          font-size: 18px;
          font-weight: 600;
          color: #000;
        }
        img{
          cursor: pointer;
        }
      }
    }
  }
  .upDown{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 30px;
    img{
      width: 28px;
    }
  }
  .introduce{
    display: flex;
    justify-content: center;
    align-items: center;
    .introduceBox{
      width: 600px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #ffa8a8;
      border-radius: 8px;
      margin-right: 10px;
      box-sizing: border-box;
      padding-right: 15px;
      img{
        height: 65px;
      }
      p{
        width: 530px;
        height: 45px;
        overflow-y: auto;
        text-align: justify;
        white-space: normal;
        line-height: 18px;
        font-size: 13px;
        box-sizing: border-box;
        padding-right: 8px;
        color: #8b8b8b;
      }
      P::-webkit-scrollbar{
        display: inline-block;
        width: 2px !important;
        background-color: #eee;
        background-clip: content-box;
      }
      p::-webkit-scrollbar-thumb{
        border-radius: 20px;
        background-color: #8f8f8d;
      }
    }
    .recordBox{
      display: flex;
      border: 1px solid #ebce7b;
      border-radius: 8px;
      .recordBoxLeftImg{
        height: 65px;
      }
      ul{
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0 30px;
        li{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span{
            font-size: 20px;
            font-weight: 600;
          }
          p{
            display: flex;
            align-items: center;
            font-size: 12px;
            img{
              margin-right: 5px;
            }
          }
        }
        label{
          width: 1px;
          height: 30px;
          background-color: #fae6d2;
          margin: 0 15px;
        }
      }
    }
  }
  .simple{
    width: 1000px;
    height: 65px;
    background: url('../assets/images/teacherJs/redBackground.png') no-repeat;
    background-size: 100% 100%;
    color: #000;
    font-size: 26px;
    font-weight: 550;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  /deep/.el-dialog__body{
    padding: 25px 15px 20px !important;
  }
  /deep/.el-dialog__headerbtn{
    top: 10px;
    right: 10px;
  }
  .ssDialog{
    font-size: 16px;
    line-height: 30px;
    white-space: pre-wrap;
  }
}

</style>