/* eslint-disable new-cap */
<template>
  <div class="bigBox">
    <div class="rightMenu" v-if="!packOrDevelop">
      <rightMenu></rightMenu>
    </div>
    <div class="develop" v-if="packOrDevelop">
      <img
        @click="controlSlider"
        src="./assets/images/craig/slider/develop_n.png"
        alt=""
      />
    </div>
    <div
      class="home-page"
      @click.stop="handleGolbalEvent"
      :class="packOrDevelop ? 'home-page-other' : ''"
    >
      <!-- head -->
      <app-head />
      <!-- main -->
      <el-main class="home-page-main">
        <div class="video-section">
          <div class="main-header video-header">
            <!-- craig 引入讲师介绍组件 -->
            <!-- <teacher-introduce></teacher-introduce> -->
            <!-- 房间切换 -->
            <!-- <div class="changeBox">
              <img class="room-change-btn" src="./assets/images/craig/changeRoom.png" alt="" @click.stop="handleRoomList">
            </div> -->
          </div>
          <!-- 主播放器 -->
          <div
            v-if="!controlNoLive"
            class="video-main"
            @mouseover="mouseOver"
            @mouseleave="mouseLeave"
          >
            <div
              v-show="ckplayerObject.live"
              id="ckplayerVideo"
              class="video-sec"
            />
            <video
              v-show="!ckplayerObject.live"
              id="ckplayerVideo"
              :src="ckplayerObject.video"
              autoplay="autoplay"
              class="video-sec"
              controls="true"
              preload
            />
            <!-- 暂时弃用 -->
            <!-- <video-player
              ref="videoPlayer"
              class="video-player vjs-custom-skin"
              :playsinline="true"
              :options="videoPlayerConfig"
              @play="onPlayerPlay($event)"
              @pause="onPlayerPause($event)"
              @ended="onPlayerEnded($event)"
            /> -->
            <div v-if="noticeControl" class="notice">
              <span
                class="noticeSpan"
                v-if="!noticeTextControl"
                @click.stop="clickNotice"
                >显示公告</span
              >
              <div class="noticeText" v-if="noticeTextControl">
                <img src="./assets/images/teacherJs/voiceWhite.png" alt="" />
                <p class="noticeText_p" @click.stop="showDialog">
                  <label for="">
                    {{
                      this.bnotes ? timestampToTime(this.bnotes[0].ut) : ""
                    }}&nbsp;
                    {{
                      this.teacherInfo ? this.teacherInfo[1].whoami : ""
                    }}老师：&nbsp;
                  </label>
                  <label
                    v-html="
                      this.bnotes ? this.getNewName(this.bnotes[0].info) : ''
                    "
                  ></label>
                </p>
                <img
                  @click="cancelNoticeText"
                  src="./assets/images/teacherJs/cancelGrey.png"
                  alt=""
                />
              </div>
            </div>
            <div class="videoSlider" v-if="noticeControl">
              <span @click="reloadVideo">刷新</span>
              <label for=""></label>
              <span @click="jumpLive">在线客服</span>
            </div>
            <div v-if="qrcodeBox" class="codeBox">
              <img class="qrcode" src="./assets/images/craig/code.png" alt="" />
              <span>下载APP</span>
              <span>随时随地享福利</span>
              <!-- <img @click="closerCode" class="roundCloser" src="./assets/images/craig/roundCloser.png" alt=""> -->
            </div>
            <!-- 礼物列表弹窗 -->
            <div class="videoGiftBox">
              <!-- 隐藏得礼物层 -->
              <transition name="el-zoom-in-center">
                <div class="hideGiftView" v-show="GiftPopupShow">
                  <!-- 左侧用户积分情况 -->
                  <div class="userIntegralInfo">
                    <div>
                      <img src="./assets/images/standings/gift_icon_1.png" />
                      <span>我的积分:</span>
                      <span>{{ userIntegral.point || 0 }}</span>
                    </div>
                    <div>
                      <img src="./assets/images/standings/gift_icon_2.png" />
                      <span>累计已领</span>
                      <span>${{ userIntegral.goldpointjs || 0 }}</span>
                    </div>
                    <div>
                      <img src="./assets/images/standings/gift_icon_3.png" />
                      <span>可领取</span>
                      <span>${{ userIntegral.goldpointwjs || 0 }}</span>
                    </div>
                  </div>
                  <!-- 右侧礼物列表 -->
                  <div class="giftList">
                    <swiper ref="mySwiper" :options="swiperOption">
                      <swiper-slide
                        class="gift"
                        v-for="item in giftList"
                        :key="item.id"
                      >
                        <el-popover
                          :ref="`popover-${item.id}`"
                          placement="top"
                          width="370"
                          trigger="click"
                        >
                          <div class="giftInfo">
                            <div class="giftImg">
                              <img :src="item.icon" />
                            </div>
                            <div class="giftRight">
                              <div class="giftRightName">
                                <!-- {{ item.name }}<span>({{ item.resourcesnum }}{{ item.gifttype === 1 ? '积分' : '赠金点' }})</span> -->
                                {{ item.name
                                }}<span>({{ item.resourcesnum }}积分)</span>
                              </div>
                              <!-- 暂时隐藏，等配置好数据再放开 -->
                              <!-- <div class="giftRightP">
                                可赠送{{ item.goldpoint }}美元，可提取，可交易
                              </div> -->
                              <div
                                class="giftRightButton"
                                @click="giveGift(item)"
                              >
                                {{ giveGiftTitle }}
                              </div>
                            </div>
                          </div>
                          <div
                            slot="reference"
                            style="height: 100%"
                            class="giftImgs"
                            @click="handleCont(item)"
                          >
                            <img :src="item.icon" />
                          </div>
                        </el-popover>
                      </swiper-slide>
                    </swiper>
                    <!-- 分页器 -->
                    <div class="swiper-button-prev">&lt;</div>
                    <div class="swiper-button-next">></div>
                  </div>
                </div>
              </transition>
              <!-- 直播礼物栏悬浮 -->
              <div
                v-if="roomInfo.isplay"
                class="giftFixed"
                @click="
                  () => {
                    this.GiftPopupShow = !this.GiftPopupShow;
                    this.closeCont();
                  }
                "
              >
                <div>
                  <img
                    src="./assets/images/standings/prev.png"
                    v-if="!GiftPopupShow"
                  />
                  <img src="./assets/images/standings/next.png" v-else />
                </div>
                <div>
                  <img src="./assets/images/standings/gift.png" />
                </div>
              </div>
            </div>
            <!-- 送出礼物悬浮窗 -->
            <div class="showGiftList" v-if="giftAnimation">
              <img :src="roomGiftList[0].useravatar" class="userHead" />
              <div class="giveGiftInfo">
                <span>{{ roomGiftList[0].nick }}</span>
                <span>送</span>
                <span>{{ roomGiftList[0].tname }}</span>
                <img :src="roomGiftList[0].gifticon" class="giftImg" />
                <!-- 产品让隐藏，后续会启用，勿删 -->
                <!-- <span>返现{{ roomGiftList[0].goldpoint }}美元!</span> -->
              </div>
            </div>
          </div>
          <advertNotLiveVue v-if="controlNoLive"></advertNotLiveVue>
          <!-- craig -- swiper模块 -->
          <swiper-banner></swiper-banner>
          <!-- 房间列表 -->
          <div v-show="showRoomList" class="room-list">
            <room-list />
            <timeTable></timeTable>
          </div>
        </div>
        <div class="chat-container">
          <div class="main-header chat-header">
            <advertNotice></advertNotice>
            <!-- 用户列表按钮 -->
            <!-- <div class="chat-header-left" @click.stop="handleUserList">
              <img
                v-if="!showUserList"
                class="user-list-btn"
                src="./assets/images/chat_user_list.png"
                alt=""
              >
              <img
                v-if="showUserList"
                class="user-list-up-btn"
                src="./assets/images/up-icon.png"
                alt=""
              >
            </div> -->
            <!-- 房间标题 -->
            <div v-if="!liveBody" class="chat-header-right">
              <!-- <span>{{ roomInfo.name ? roomInfo.name : "聊天室" }}</span> -->
              <!-- <span :class="controlWindow ? 'currentSpan' : ''" @click="()=>{this.controlWindow = true}">
                <label v-if="!roomInfo.popularity" for="">互动窗口</label>
                <label v-else for="">人气值：{{roomInfo.popularity}}</label>
              </span>
              <span :class="!controlWindow ? 'currentSpan' : ''" @click="()=>{this.controlWindow = false}">课堂讲义</span>
              <span :class="!controlWindow ? 'currentSpan' : ''" @click="()=>{this.controlWindow = false}">积分榜</span> -->
              <span
                v-for="item in tabsList"
                :index="item.index"
                :class="controlWindow === item.index ? 'currentSpan' : ''"
                @click="tabsClick(item)"
              >
                <template v-if="item.index === '0'">
                  <label v-if="!roomInfo.popularity" for="">互动窗口</label>
                  <label v-else for="">人气值：{{ roomInfo.popularity }}</label>
                </template>
                <template v-else>
                  {{ item.name }}
                </template>
              </span>
              <!-- <div class="chat-header-line" /> -->
            </div>
            <div v-else class="chat-header-right liveTit">
              <img src="./assets/images/craig/goback.png" alt="" />
              <span>{{ this.distributecustomer.nick }}</span>
              <img src="" alt="" />
            </div>
            <!-- 房间客服按钮 -->
            <!-- <div class="chat-header-living" @click.stop="handlePrivateChatEvent(1, {}, true)">
              <img src="./assets/images/living-icon.png" alt="客服私聊">
            </div> -->
          </div>
          <div class="chat-main">
            <!-- 聊天区域 -->
            <div v-if="!liveBody" class="chat-section">
              <!-- 聊天内容展示区域 -->
              <div v-if="controlWindow === '1'" class="chatListBox">
                <chat-list @handleEvent="handleEvent" />
              </div>
              <!-- craig -- 课堂讲义模块 -->
              <div v-else-if="controlWindow === '2'">
                <handouts></handouts>
              </div>
              <!-- 积分榜 -->
              <div v-else class="standings">
                <!-- 左侧导航条 -->
                <div class="navList">
                  <div
                    v-for="item in navList"
                    :key="item.index"
                    :class="[
                      'navItem',
                      navListActive === item.index ? 'navItem_active' : '',
                    ]"
                    @click="navItemClick(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <!-- 积分榜内容 -->
                <div class="s_center">
                  <!-- 老师粉丝榜 -->
                  <div class="teacherFanList" v-if="navListActive === '1'">
                    <!-- 老师头像选择老师区域 -->
                    <div class="teacherHead">
                      <div
                        class="t_name"
                        @click="searchDialogShow = !searchDialogShow"
                      >
                        {{
                          teacherListInfo ? teacherListInfo.whoami : "--老师"
                        }}
                      </div>
                      <div class="t_head">
                        <img
                          :src="teacherListInfo.avatar"
                          v-if="teacherListInfo"
                        />
                      </div>
                      <span>粉丝榜</span>
                    </div>
                    <!-- 本周 上周  总榜 榜单 -->
                    <div class="s_list">
                      <div class="s_list_tab">
                        <div
                          v-for="item in s_listData"
                          :key="item.index"
                          :class="
                            s_list_active === item.index ? 'itemActive' : ''
                          "
                          @click="listTabsClick(item)"
                        >
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                    <!-- 榜单 -->
                    <div class="fansList">
                      <!-- 前三名 -->
                      <div class="topThree" v-if="teacherFanLists.length > 0">
                        <div class="topTow">
                          <img src="./assets/images/standings/two.png" />
                          <div class="top_head">
                            <img
                              :src="teacherFanLists[1].avatar"
                              v-if="
                                teacherFanLists[1] && teacherFanLists[1].avatar
                              "
                            />
                          </div>
                          <div class="fansName">
                            {{
                              teacherFanLists[1] ? teacherFanLists[1].nick : ""
                            }}
                          </div>
                          <div class="fansIntegral">
                            {{
                              teacherFanLists[1]
                                ? teacherFanLists[1].giftpoints
                                : 0
                            }}积分
                          </div>
                        </div>
                        <div class="topOne">
                          <img src="./assets/images/standings/one.png" />
                          <div class="top_head">
                            <img
                              :src="teacherFanLists[0].avatar"
                              v-if="
                                teacherFanLists[0] && teacherFanLists[0].avatar
                              "
                            />
                          </div>
                          <div class="fansName">
                            {{
                              teacherFanLists[0] ? teacherFanLists[0].nick : ""
                            }}
                          </div>
                          <div class="fansIntegral">
                            {{
                              teacherFanLists[0]
                                ? teacherFanLists[0].giftpoints
                                : 0
                            }}积分
                          </div>
                        </div>
                        <div class="topsan">
                          <img src="./assets/images/standings/three.png" />
                          <div class="top_head">
                            <img
                              :src="teacherFanLists[2].avatar"
                              v-if="
                                teacherFanLists[2] && teacherFanLists[2].avatar
                              "
                            />
                          </div>
                          <div class="fansName">
                            {{
                              teacherFanLists[2] ? teacherFanLists[2].nick : ""
                            }}
                          </div>
                          <div class="fansIntegral">
                            {{
                              teacherFanLists[2]
                                ? teacherFanLists[2].giftpoints
                                : 0
                            }}积分
                          </div>
                        </div>
                      </div>
                      <!-- 粉丝列表 -->
                      <div class="fansTopList" v-if="teacherFanList.length > 0">
                        <div
                          class="fansTopListItem"
                          v-for="item in teacherFanList"
                          :key="item.indexs"
                        >
                          <div class="fansItemLeft">
                            <div class="ranking">{{ item.indexs }}</div>
                            <div class="fansHead">
                              <img :src="item.avatar" v-if="item.avatar" />
                            </div>
                            <div class="fans_name">
                              {{ item.nick }}
                            </div>
                          </div>
                          <div class="fansItemRight">
                            <div>
                              <span>{{ item.giftpoints }}</span
                              >积分
                            </div>
                            <div class="trend" v-if="s_list_active !== '3'">
                              <img
                                v-if="
                                  (item.thispm !== 0 &&
                                    item.lastpm !== 0 &&
                                    item.thispm < item.lastpm) ||
                                    (item.lastpm === 0 &&
                                      item.thispm !== 0 &&
                                      item.thispm > item.lastpm)
                                "
                                src="./assets/images/standings/top.png"
                              />
                              <img
                                v-else-if="item.thispm > item.lastpm"
                                src="./assets/images/standings/bottom.png"
                              />
                              <span v-else>--</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 选择老师弹窗 -->
                    <el-collapse-transition>
                      <div class="searchDialog" v-show="searchDialogShow">
                        <div
                          class="teacherList"
                          v-for="item in teacherList"
                          :key="item.id"
                          @click="switchTeacher(item)"
                        >
                          <div class="teacherHeadImg">
                            <img :src="item.avatar" />
                          </div>
                          <div class="teacherName">{{ item.whoami }}</div>
                        </div>
                      </div>
                    </el-collapse-transition>
                  </div>
                  <!-- 本周讲师排行榜 和本周粉丝排行榜 -->
                  <div
                    class="teacherFanList"
                    v-if="navListActive === '2' || navListActive === '3'"
                  >
                    <!-- 榜单 -->
                    <div class="fansList">
                      <!-- 粉丝列表 -->
                      <div class="fansTopList">
                        <template v-for="(item, index) in teacherFanLists">
                          <div
                            class="fansTopListItem"
                            v-if="index <= 9"
                            :key="index"
                          >
                            <div class="fansItemLeft">
                              <div
                                :class="[
                                  'ranking',
                                  index === 0
                                    ? 'ranking_0'
                                    : index === 1
                                    ? 'ranking_1'
                                    : index === 2
                                    ? 'ranking_2'
                                    : '',
                                ]"
                              >
                                {{
                                  index !== 0 && index !== 1 && index !== 2
                                    ? index + 1
                                    : ""
                                }}
                              </div>
                              <div class="fansHead">
                                <img :src="item.avatar" v-if="item.avatar" />
                              </div>
                              <div class="fans_name">
                                {{
                                  navListActive === "2" ? item.tname : item.nick
                                }}
                              </div>
                            </div>
                            <div class="fansItemRight">
                              <div>
                                <span>{{ item.giftpoints }}</span
                                >积分
                              </div>
                              <div class="trend">
                                <img
                                  v-if="
                                    (item.thispm !== 0 &&
                                      item.lastpm !== 0 &&
                                      item.thispm < item.lastpm) ||
                                      (item.lastpm === 0 &&
                                        item.thispm !== 0 &&
                                        item.thispm > item.lastpm)
                                  "
                                  src="./assets/images/standings/top.png"
                                />
                                <img
                                  v-else-if="item.thispm > item.lastpm"
                                  src="./assets/images/standings/bottom.png"
                                />
                                <span v-else>--</span>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <!-- 榜单规则 -->
                  <div class="s_bottom">
                    <div class="listRules">
                      *榜单统计自{{
                        weeksTime.startDateTime + "-" + weeksTime.endDateTime
                      }}。送积分返赠金。

                      <!-- ，详情见<span @click="integralTextDialog = true">积分打赏细则</span> -->
                    </div>
                    <div
                      class="userRanking"
                      v-if="userInfo.id && navListActive !== '2'"
                    >
                      <div class="userRankingLeft">
                        <div class="header">
                          <img :src="userInfo.avatar" />
                        </div>
                        <div class="userInfo">
                          <div>{{ userInfo.nick }}</div>
                          <div>当前排名:{{ userRanking.pm }}名</div>
                        </div>
                      </div>
                      <div class="userRankingRight">
                        已赠送积分:{{ userRanking.giftpoints }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 新增客服聊天区域 -->
            <div v-else class="chat-live">
              <liveList></liveList>
            </div>
            <!-- 功能按钮区域（禁止聊天自动滚动） -->
            <div class="chat-handler">
              <div class="scroll-control">
                <!-- <img v-if="!isatuoscroll" src="./assets/images/start-scroll-icon.png" alt="" @click.stop="handleAutoScroll(true)">
                <img v-if="isatuoscroll" src="./assets/images/stop-scroll-icon.png" alt="" @click.stop="handleAutoScroll(false)"> -->
                <!-- craig -- 新增 -->
                <img
                  @click.stop="
                    () => {
                      controlShowChat = !controlShowChat;
                    }
                  "
                  src="./assets/images/craig/select.png"
                  alt=""
                />
                <div class="newLiveBox">
                  <!-- 当前登录用户为客服时 显示当前房间在线用户列表按钮 -->
                  <div
                    v-if="userInfo.utype == 2"
                    @click.stop="handleUserList"
                    class="jumpCustom"
                  >
                    客户列表
                  </div>
                  <p @click.stop="live800">
                    讲师助理
                    <span
                      v-if="
                        showRed && userInfo.utype !== 1 && userInfo.utype !== 2
                      "
                      class="jumping-text"
                      >{{ customMsgTotal }}</span
                    >
                  </p>
                  <div @click.stop="jumpLive" class="jumpLive">在线客服</div>
                </div>
                <ul v-if="controlShowChat">
                  <li
                    :class="controlClass == 1 ? 'liRed' : ''"
                    @click.stop="selectShow(1)"
                  >
                    全部
                  </li>
                  <li
                    :class="controlClass == 2 ? 'liRed' : ''"
                    @click.stop="selectShow(2)"
                  >
                    只看我
                  </li>
                  <li
                    :class="controlClass == 3 ? 'liRed' : ''"
                    @click.stop="selectShow(3)"
                  >
                    只看讲师
                  </li>
                </ul>
              </div>
            </div>
            <!-- 发送区域 -->
            <div v-if="!liveBody" class="send-section">
              <!-- 输入区域 -->
              <div class="send-input">
                <textarea
                  ref="inputSection"
                  v-model="sendTxt"
                  @keyup.enter="handleSendEvent"
                  :disabled="craig || controlWindow === '2'"
                />
                <p v-if="craig" class="textareaTip">
                  <span @click="handleLogin(3)" style="color: #e90014"
                    >登录/注册
                  </span>
                  即可与讲师畅所欲言
                </p>
                <!-- 发送按钮 -->
                <div class="send-btn">
                  <span>按Enter键 发送消息</span>
                  <button
                    :disabled="craig || controlWindow === '2'"
                    @click.stop="handleSendEvent"
                  >
                    发送
                  </button>
                </div>
              </div>
            </div>
            <!-- 回复引用展示区域 -->
            <div v-if="replyCall.show" class="reply-section">
              <span>{{ replyCall.name }}</span>
              <p>{{ replyCall.txt }}</p>
              <span class="class-reply-btn" @click="handleCloseReplyEvent" />
            </div>
          </div>
          <!-- 房间列表 -->
          <transition name="fade">
            <div v-if="showUserList" class="user-list">
              <!-- 用户列表组件 -->
              <user-list @openChat="openChat" @closeUser="handleUserList" />
            </div>
          </transition>
        </div>
      </el-main>
      <el-dialog
        :visible.sync="dialogObj.dialogVisible"
        :width="dialogObj.width"
        :show-close="false"
        :close-on-click-modal="false"
      >
        <!-- 关闭按钮 -->
        <div
          v-if="dialogObj.isCloseIcon"
          class="dialog-close"
          @click.stop="handleCloseDialog"
        />
        <!-- 登录弹出组件 -->
        <login-item
          v-if="dialogObj.type === 1"
          @loginSuccess="handleLoginSuccess"
        />
        <!-- 禁言弹出组件 -->
        <stop-word v-if="dialogObj.type === 2" />
        <!-- 私聊弹出组件 -->
        <private-talk v-if="dialogObj.type === 3" />
      </el-dialog>
      <el-dialog
        title=""
        :show-close="false"
        :visible.sync="craigDialog.registerVisible"
        :width="craigDialog.registerWidth"
      >
        <div class="registerBox">
          <img
            class="registerSuccess"
            src="./assets/images/craig/registerSuccess.png"
            alt=""
          />
          <div class="registerCenter">
            <div class="registerCenterLeft">
              <p>
                <img src="./assets/images/craig/code.png" alt="" />
                <span>扫码下载APP</span>
              </p>
              <button>IOS版</button>
              <button>Android版</button>
            </div>
            <div class="registerCenterRight">
              已下为您的账户密码：
              <p>
                <span>登录账号：{{ registerAfterInfo.username }}</span>
                <span>登录密码：{{ registerAfterInfo.password }}</span>
              </p>
              尊敬的用户为了方便您的交易，红狮为您生成了MT4交易账号<br />
              您可以使用MT4交易账号交易，也可以直接登录直播间，以上信息将会发送至您的手机<br />
              我们会严格保护您的数据安全
            </div>
          </div>
          <div class="registerButton">
            <p @click="closerDialog">返回直播间</p>
            <p>充多少送多少</p>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        :visible.sync="dialogObj3.dialogVisible"
        :width="dialogObj3.width"
        :show-close="false"
        class="loginBox"
      >
        <!-- 关闭按钮 -->
        <div
          v-if="dialogObj3.isCloseIcon"
          class="dialog-close"
          @click.stop="handleCloseDialog3"
        />
        <!-- 忘记密码弹出组件 -->
        <forget-item
          v-if="dialogObj3.type === 1"
          @loginSuccess="handleLoginSuccess"
        />
      </el-dialog>
      <el-dialog
        :visible.sync="dialogObj4.dialogVisible"
        :width="dialogObj4.width"
        :show-close="false"
        :close-on-click-modal="false"
      >
        <div
          v-if="dialogObj4.isCloseIcon"
          class="dialog-close"
          @click.stop="handleCloseDialog4"
        />
        <div class="dialog-craigNew">
          <p class="craigNewP">红狮课堂</p>
          <div>
            <h3 v-if="dialogObj4.gq">
              感谢关注，您的观看时间剩余<span>{{ this.tipTime.minute }}</span
              >分钟!
            </h3>
            <h3 v-if="!dialogObj4.gq">感谢关注，游客观看时间已结束!</h3>
            <p>马上注册或者登录账号，观看海量精彩直播</p>
            <button @click="handleLogin(1)">登录</button>
            <button @click="handleLogin(2)">
              <img src="./assets/images/craig/500.png" alt="" />
              注册立领$500现金券
            </button>
          </div>
        </div>
      </el-dialog>
      <div
        class="shade"
        v-if="rightMenu || isVip || noSpeak || teacherVisible"
        @click="shadeMenu"
      ></div>
      <div class="timetable" v-if="timeTable">
        <timeTable></timeTable>
      </div>
      <div class="noSpeak" v-if="noSpeak">
        <noSpeak></noSpeak>
      </div>
      <div v-if="isVip" class="vipBox">
        <img src="./assets/images/craig/vipbanner.png" alt="" />
        <div>
          <span>请输入房间密码</span>
          <input type="text" v-model="pwd" />
        </div>
        <p>
          <span @click="cancel_vip">取消</span>
          <span @click="confirm_vip">确认</span>
        </p>
      </div>
    </div>
    <div v-if="isVip" class="vipBox">
      <img src="./assets/images/craig/vipbanner.png" alt="" />
      <div>
        <span>请输入房间密码</span>
        <input type="text" v-model="pwd" />
      </div>
      <p>
        <span @click="cancel_vip">取消</span>
        <span @click="confirm_vip">确认</span>
      </p>
    </div>
    <div @click="changeShadow" v-if="showRoomList" class="shadow"></div>
    <!-- 显示公告的详情挪出来 -->
    <div v-if="teacherVisible" class="ssDialog">
      <p class="ssContent" v-html="this.bnotes ? this.bnotes[0].info : ''"></p>
      <img
        class="controlSpeak"
        @click="showDialog"
        src="./assets/images/teacherJs/cancelGrey.png"
        alt=""
      />
    </div>
    <!-- 积分打赏明细 -->
    <el-dialog title="积分打赏明细" center :visible.sync="integralTextDialog">
      <div class="dialog_center">
        <p>1、参与资格：所有红狮投资者</p>
        <p>2、活动时间：即日起</p>
        <p>3、活动内容：为实况讲堂的讲师赠送积分礼物，笔笔有现金回赠。</p>
        <p>4、赠送礼物与赠金对照如下：</p>
        <table class="modal-table">
          <tbody>
            <tr class="firstRow">
              <td
                colspan="1"
                align="center"
                valign="middle"
                rowspan="1"
                width="126"
              >
                <p>赠送礼物</p>
              </td>
              <td rowspan="1" valign="middle" align="center" width="126">
                消耗积分值
              </td>
              <td rowspan="1" valign="middle" align="center" width="126">
                可领赠金
              </td>
            </tr>
            <tr v-for="item in giftList" :key="item.id">
              <td width="132" align="center" valign="middle">
                <p>{{ item.name }}</p>
              </td>
              <td width="185" align="center" valign="middle">
                <p>{{ item.resourcesnum }}</p>
              </td>
              <td width="90" align="center" valign="middle">
                <p>${{ item.goldpoint }}<br /></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p><span>举例：</span></p>
        <p>
          <span
            >用户张先生送出2个止盈符，消耗积分：2×1500=3000分。待领赠金金额：20×2=40美元。</span
          >
        </p>
        <p>5、赠金金额审批通过即可发放，赠金最晚到账时间为次日12:00。</p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;●
          赠金将添加至客户MT4交易账号，可提取、可交易。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;● 赠金金额及领取次数均不设上限。
        </p>
        <p>6、积分的获取，参见“积分奖励计划”细则。</p>
        <p>
          7、如遇互联网通讯等不可抗力因素，导致赠礼失败，积分值将退回给用户，并取消该次赠金奖赏，客户可以选择重新赠送。
        </p>
        <p>
          8、本公司保留随时修改、暂停、终止本计划及任何相关规则条款与替换其他等值赠品之权利及其解释权，毋须事前通知客户。
        </p>
        <p>*活动资格及奖励受条款及细则约束，活动最终解释权归本公司所有。</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AppHead from "./components/appHead";
import RoomList from "./components/roomList";
import UserList from "./components/userList";
import ChatList from "./components/chatList";
import LoginItem from "./components/login";
import StopWord from "./components/stopwords";
import PrivateTalk from "./components/privatetalk";
import TeacherIntroduce from "./components/teacherIntroduce.vue";
import config from "./config/index";
import SwiperBanner from "./components/swiperBanner.vue";
import Handouts from "./components/handouts.vue";
import ForgetItem from "./components/forget";
import liveList from "./components/liveList.vue";
import rightMenu from "./components/rightMenu.vue";
import timeTable from "./components/timeTable.vue";
import noSpeak from "./components/noSpeak.vue";
import advertNotice from "./components/advertNotice.vue";
import advertNotLiveVue from "./components/advertNotLive.vue";
import {
  getTeacher,
  getGiftList,
  getPrezzies,
  giveGiftList,
  GetGiveGiftList,
  getUserIntegralData,
} from "./api/home";
import { getAccountInfo as getAccountInfoApi } from "./api/login";
export default {
  name: "classRoom",
  components: {
    AppHead,
    RoomList,
    UserList,
    ChatList,
    LoginItem,
    StopWord,
    PrivateTalk,
    TeacherIntroduce,
    SwiperBanner,
    Handouts,
    ForgetItem,
    liveList,
    rightMenu,
    timeTable,
    noSpeak,
    advertNotice,
    advertNotLiveVue,
  },
  data() {
    return {
      giftAnimation: false, // 是否显示礼物动画
      userIntegral: {}, // 用户积分信息
      integralTextDialog: false, // 积分打赏明细
      userRanking: {}, // 用户榜单排名
      teacherFanLists: [], // 老师粉丝排行榜
      teacherFanList: [], // 去掉前三名排行榜
      giveGiftTitle: "立即赠送",
      teacherListInfo: null, // 榜单老师信息
      teacherList: [], // 老师列表
      weeksTime: {}, // 本周时间
      searchDialogShow: false,
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      GiftPopupShow: false,
      tabsList: [
        {
          name: "互动窗口",
          index: "1",
        },
        {
          name: "课堂笔记",
          index: "2",
        },
        // {
        //   name: "积分榜",
        //   index: "3",
        // },
      ],
      navList: [
        {
          name: "老师粉丝榜",
          index: "1",
        },
        {
          name: "本周讲师榜",
          index: "2",
        },
        {
          name: "本周粉丝榜",
          index: "3",
        },
      ],
      navListActive: "1", // 积分榜左侧tab当前选中index
      s_listData: [
        {
          name: "本周",
          index: "1",
        },
        {
          name: "上周",
          index: "2",
        },
        {
          name: "总榜",
          index: "3",
        },
      ],
      s_list_active: "1", // 本周 上周 总榜
      giftList: [], // 直播礼物列表

      sendTxt: "", // 发送的内容
      sendType: 0, // 发送的消息类型:0、发送普通消息 1、发送回复引用消息 (2、禁言 3、私聊 4、撤回发言) 5、发送@别人的消息
      replyCall: {
        show: false,
        name: "",
        txt: "",
      },
      sendData: {
        broomid: "", // 房间id
        sname: "", // 消息发送人昵称
        sid: "", // 消息发送人id
        rid: "", // 消息接收人id(@别人使用，其他情况为空)
        rname: "", // 消息接收人昵称(@别人使用，其他情况为空)
        type: 0, // 消息类型(11、私聊（必传）)
        param: {
          TXT: "", // 消息内容
          CITE_TXT: "", // 回复引用的内容（回复引用功能下使用）
          CITE_TXT_ID: "", // 回复引用消息id（回复引用功能下使用）
          CITE_TXT_NAME: "", // 回复引用的昵称（回复引用功能下使用）
        },
      },
      craigDialog: {
        registerVisible: false,
        registerWidth: "500px",
      },
      // 控制切换互动窗口和课堂讲义
      controlWindow: "1",
      // 控制筛选聊天盒子是否显示
      controlShowChat: false,
      controlClass: "",
      screenWidth: null,
      // isLogin:false,
      imgSrc: require("./assets/images/craig/avatar/1.png"),
      timer: null,
      liveBody: false,
      // 主页面二维码控制
      qrcodeBox: true,
      // 双向绑定vip房间密码
      pwd: "",
      // 控制video上的按钮
      noticeControl: false,
      // 控制公告文字的展示
      noticeTextControl: false,
      // 显示老师笔记第一条的详情
      teacherVisible: false,
    };
  },
  computed: {
    roomGiftList() {
      return this.$store.state.home.roomGiftList;
    },
    showRoomList() {
      return this.$store.state.home.showRoomList;
    },
    ckplayerObject() {
      return this.$store.state.home.ckplayerObject;
    },
    player() {
      return this.$store.state.home.player;
    },
    showUserList() {
      return this.$store.state.home.showUserList;
    },
    dialogObj() {
      return this.$store.state.home.dialogObj;
    },
    dialogObj3() {
      return this.$store.state.home.dialogObj3;
    },
    dialogObj4() {
      return this.$store.state.home.dialogObj4;
    },
    userInfo() {
      if (
        !this.$store.state.login.userInfo.id ||
        this.$store.state.login.userInfo.nick.indexOf("游客") !== -1
      ) {
        this.giveGiftTitle = "立即登录";
      } else if (!this.$store.state.login.userInfo.IsBasicAuthentication) {
        this.giveGiftTitle = "激活账户，立即赠送";
      } else if (!this.$store.state.login.userInfo.HasFirstDeposit) {
        this.giveGiftTitle = "激活账户，立即赠送";
      } else {
        this.giveGiftTitle = "立即赠送";
      }
      return this.$store.state.login.userInfo;
    },
    roomList() {
      return this.$store.state.home.roomList;
    },
    roomInfo() {
      return this.$store.state.home.roomInfo;
    },
    socket() {
      return this.$store.state.home.socket;
    },
    privateUserList() {
      return this.$store.state.home.privateUserList;
    },
    videoPlayerConfig() {
      return this.$store.state.home.videoPlayerConfig;
    },
    roomset() {
      return this.$store.state.home.roomset;
    },
    roomtid() {
      return this.$store.state.home.roomtid;
    },
    isatuoscroll() {
      return this.$store.state.home.isatuoscroll;
    },
    registerSuccess() {
      return this.$store.state.login.registerSuccess;
    },
    // craig--newAdd
    teacherInfo() {
      this.teacherListInfo = this.$store.state.home.teacherInfo[1];
      this.navList[0].name = this.$store.state.home.teacherInfo[1]
        ? this.$store.state.home.teacherInfo[1].whoami + "老师粉丝榜"
        : "老师粉丝榜";
      return this.$store.state.home.teacherInfo;
    },
    tipTime() {
      return this.$store.state.home.tipTime;
    },
    isguestmessage() {
      return this.$store.state.home.isguestmessage;
    },
    userPwd() {
      return this.$store.state.home.userPwd;
    },
    isLogin() {
      return this.$store.state.login.isLogin;
    },
    chartShowControl() {
      return this.$store.state.login.chartShowControl;
    },
    controlTimer() {
      return this.$store.state.home.controlTimer;
    },
    distributecustomer() {
      return this.$store.state.home.distributecustomer;
    },
    showRed() {
      return this.$store.state.home.showRed;
    },
    registerAfterInfo() {
      return this.$store.state.login.registerAfterInfo;
    },
    rightMenu() {
      return this.$store.state.home.rightMenu;
    },
    timeTable() {
      return this.$store.state.home.timeTable;
    },
    noSpeak() {
      return this.$store.state.home.noSpeak;
    },
    craig() {
      if (this.userInfo.id && this.userInfo.nick.indexOf("游客") == -1) {
        clearInterval(this.timer);
        if (this.dialogObj4.dialogVisible) {
          this.$store.commit("home/set_dialogobj4", {
            dialogVisible: false,
            isCloseIcon: true,
          });
        }
        return false;
      } else {
        if (this.isguestmessage == 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    isVip() {
      return this.$store.state.home.isVip;
    },
    vipRoom() {
      return this.$store.state.home.vipRoom;
    },
    packOrDevelop() {
      return this.$store.state.home.packOrDevelop;
    },
    bnotes() {
      return this.$store.state.home.bnotes;
    },
    isReload() {
      return this.$store.state.home.isReload;
    },
    controlNoLive() {
      return this.$store.state.home.controlNoLive;
    },
    customMsgTotal() {
      return this.$store.state.home.customMsgTotal;
    },
  },
  created() {
    this.weeksTime = this.getCurrentWeek(null, "-", "本周");
    this.pageInit();
    // craig-- 获取老师和课程信息
    this.getTeacherInfo();
    setTimeout(() => {
      // craig--获取课堂笔记列表
      this.getBnotes();
    }, 800);
    // craig--获取登录提示时间间隔
    this.loginSetTime();
    // 传给App.vue,不展示tabbar
    this.$emit("controlShow", true);
    // 通过token判断是否已登录,通过nick判断是否为游客，通过utype判断是否禁止发言
    if (this.userInfo.utype == 1) {
      this.$store.commit("login/set_isLogin", true);
    } else {
      if (this.userInfo.nick && this.userInfo.nick.indexOf("游客") !== -1) {
        this.$store.commit("login/set_isLogin", false);
      } else if (JSON.parse(sessionStorage.getItem("token")) && JSON.parse(sessionStorage.getItem("token")).token) {
        this.$store.commit("login/set_isLogin", true);
      }
    }
    this.getAccountInfo({
      account: this.$store.state.login.userInfo.mtaccr,
    });
    // 获取用户积分
    this.getUserIntegral();
    console.log("我是页面加载进来的", this.isLogin);
  },
  watch: {
    roomGiftList(newVal) {
      console.log(newVal, "newVal");
      if (newVal.length >= 1) {
        // 获取直播间送礼物信息
        this.$nextTick(() => {
          if (!this.giftAnimation) {
            // 动画已经停止了 点了新的礼物 执行新的定时器
            this.giftAnimation = true;
            let giftAnimation = setInterval(() => {
              if (this.roomGiftList.length === 1) {
                // 最后一个数组 也是要停止定时器
                this.roomGiftList.shift();
                clearInterval(giftAnimation);
                this.giftAnimation = false;
              } else if (this.roomGiftList.length) {
                // 有长度的话 就必须要删除数组
                this.roomGiftList.shift();
              } else {
                //  没有数组的时候 就要停止定时器
                clearInterval(giftAnimation);
                this.giftAnimation = false;
              }
            }, 2000);
          } else {
          } // 正在进行动画的话 就不再添加定时器
        });
      }
    },
    registerSuccess(newVal) {
      if (newVal) {
        this.craigDialog.registerVisible = true;
      }
    },
    tipTime(newVal) {
      if (newVal) {
        // craig--隔固定时间打开提示弹框
        console.log("我是后台配置的", this.isLogin);
        if (!this.isLogin) {
          this.craigSetTime();
        }
      }
    },
    controlTimer(newVal) {
      if (newVal) {
        clearInterval(this.timer);
      }
    },
    // 监听路由变化
    $route(to) {
      console.log(to);
    },
    screenWidth: function(n) {
      if (n <= 500) {
        this.$router.push({
          name: "classRoomWap",
        });
      }
    },
  },
  mounted() {
    this.getTeacherList();
    this.getGiftData(); // 获取礼物列表
    this.$store.commit("home/set_craigPlay", true);
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    // 阻止chartlist输入框的键盘事件冒泡
    this.$nextTick(() => {
      let that = this;
      let myTextarea = that.$refs["inputSection"]; //chartlist输入区
      //监听键盘方向左右键
      myTextarea.addEventListener("keydown", (event) => {
        if (event.keyCode === 37) {
          event.stopPropagation();
        } else if (event.keyCode === 39) {
          event.stopPropagation();
        } else if (event.keyCode === 13) {
          event.preventDefault();
          event.stopPropagation();
        }
      });
    });
  },
  destroyed() {
    sessionStorage.setItem("destroyed", true);
  },
  methods: {
    // 查询用户信息
    getAccountInfo(params) {
      getAccountInfoApi({...params,platid:1}).then((res) => {
        if(res.IsSuccess) {
          this.$store.commit(
            "login/set_userInfo",
            Object.assign({}, this.$store.state.login.userInfo, {
              HasFirstDeposit: res.Data.isFirst,
              IsBasicAuthentication: res.Data.authenticationMethod,
              // Uid: res.data.Uid,
            })
          );
        }
      }).catch(err=>{
        console.log(err)
      });
    },
    // 获取用户积分信息
    getUserIntegral() {
      getUserIntegralData({
        mtaccr: this.userInfo.mtaccr,
        platid: 1,
        resourcetype: 1,
      }).then((res) => {
        if (res.Status === 0) {
          this.userIntegral = res.content;
        }
      });
    },
    // 赠送礼物事件
    giveGift(gift) {
      let host = location.host;
      // 未登录  提醒去登录
      if (
        !this.userInfo.id ||
        this.$store.state.login.userInfo.nick.indexOf("游客") !== -1
      ) {
        this.handleLogin(1);
        return;
      }
      // 已登录 未基础认证
      if (!this.userInfo.IsBasicAuthentication) {
        if (
          host.indexOf("localhost") > -1 ||
          host.indexOf(".lan") > -1 ||
          host.indexOf("8080") > -1 ||
          host.indexOf("testwww.rlc525") > -1
        ) {
          window.location.href =
            "http://test.www.rlcvipltd.com/uc/userCenter/userInfo";
        } else {
          window.location.href =
            "https://www.rlcproltd.net/uc/userCenter/userInfo";
        }
        return;
      }
      // 已登录  未入金
      if (!this.userInfo.HasFirstDeposit) {
        if (
          host.indexOf("localhost") > -1 ||
          host.indexOf(".lan") > -1 ||
          host.indexOf("8080") > -1 ||
          host.indexOf("testwww.rlc525") > -1
        ) {
          window.location.href =
            "http://test.www.rlcvipltd.com/uc/assets/deposit";
        } else {
          window.location.href = "https://www.rlcproltd.net/uc/assets/deposit";
        }
        return;
      }
      // 判断用户积分是否足够
      // gift.type === 1
      if (this.userIntegral.point < gift.resourcesnum) {
        this.$alert("赠送失败，剩余积分不足！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      //
      // 送礼物
      giveGiftList({
        userid: this.userInfo.id,
        giftid: gift.id,
        tid: this.teacherInfo[1].id,
        tname: this.teacherInfo[1].whoami,
        broomid: this.roomInfo.id,
        platid: 1,
      }).then((res) => {
        if (res.Status === 0) {
          const keys = "popover-" + gift.id; //当前ref的keygifts
          this.$refs[keys][0].doClose();
          let option = {
            id: res.content.id,
            msgtype: "gift",
          };
          // 给服务端推送送礼物消息
          this.socket.send(JSON.stringify(option));
          // 礼物赠送成功重新刷新用户自己积分
          this.getUserIntegral();
        }
      });
    },
    // 点击礼物事件
    handleCont(item) {
      const keys = "popover-" + item.id; //当前ref的keys
      for (const key in this.$refs) {
        if (key.indexOf("popover-") !== -1 && keys !== key) {
          this.$refs[key][0].doClose();
        }
      }
    },
    // 关闭礼物事件
    closeCont() {
      console.log("asdasdasdasd");
      for (const key in this.$refs) {
        if (key.indexOf("popover-") !== -1) {
          this.$refs[key][0].doClose();
        }
      }
    },
    // 选择老师事件
    switchTeacher(item) {
      this.teacherListInfo = item;
      this.searchDialogShow = false;
      this.s_list_active = "1";
      this.weeksTime = this.getCurrentWeek(null, "-", "本周");
      this.getGiftListData({
        tid: this.teacherListInfo.id,
        userid: this.userInfo.id,
        group: "userid",
      });
    },
    // 获取本周和上周时间
    getCurrentWeek(value = null, separate = "-", type) {
      // 如果为null,则格式化当前时间
      if (!value) value = Number(new Date());
      // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
      if (value.toString().length == 10) value *= 1000;
      value = +new Date(Number(value));

      const one_day = 86400000; // 24 * 60 * 60 * 1000;
      const weekDate =
        type === "本周" ? new Date(value) : new Date(value - 7 * one_day);
      const day = weekDate.getDay() === 0 ? 7 : weekDate.getDay(); // 返回1-7,7表示周日
      // 设置时间为当天的0点
      weekDate.setHours(0, 0, 0, 0);

      //算出本周开始时间结束时间
      const week_start_time = new Date(
        weekDate.getTime() - (day - 1) * one_day
      );
      const week_end_time = new Date(weekDate.getTime() + (7 - day) * one_day);

      //格式化日期
      const filters = (n) => {
        return n < 10 ? (n = "0" + n) : n;
      };
      const startmonth = filters(week_start_time.getMonth() + 1);
      const startDay = filters(week_start_time.getDate());
      const endmonth = filters(week_end_time.getMonth() + 1);
      const endDay = filters(week_end_time.getDate());

      const startDateTime =
        week_start_time.getFullYear() +
        separate +
        startmonth +
        separate +
        startDay;
      const endDateTime =
        week_end_time.getFullYear() + separate + endmonth + separate + endDay;
      return {
        startDateTime: startDateTime + " 00:00:00",
        endDateTime: endDateTime + " 23:59:59",
      };
    },
    // 获取老师列表
    getTeacherList() {
      getTeacher({
        platid: 1,
        utype: 1,
      }).then((res) => {
        if (res.Status === 0) {
          this.teacherList = [...res.content];
        }
      });
    },
    // 积分榜日期tab点击事件
    listTabsClick(item) {
      if (item.index === "2") {
        this.weeksTime = this.getCurrentWeek(null, "-", "上周");
      } else {
        this.weeksTime = this.getCurrentWeek(null, "-", "本周");
      }
      this.s_list_active = item.index;
      this.getGiftListData({
        tid: this.teacherListInfo.id,
        userid: this.userInfo.id,
        group: "userid",
      });
    },
    // 积分榜tab点击事件
    navItemClick(item) {
      if (item.index === "3") {
        this.getGiftListData({
          group: "userid",
          userid: this.userInfo.id,
        });
      } else if (item.index === "1") {
        // 老师粉丝排行榜
        this.s_list_active = "1";
        this.getGiftListData({
          tid: this.teacherListInfo ? this.teacherListInfo.id : "",
          userid: this.userInfo.id,
          group: "userid",
        });
      } else if (item.index === "2") {
        this.getGiftListData({
          group: "tid",
        });
      }
      this.weeksTime = this.getCurrentWeek(null, "-", "本周");
      this.navListActive = item.index;
    },
    // 房间tabs点击事件
    tabsClick(item) {
      this.controlWindow = item.index;
      if (item.index === "3") {
        // 判断如果没有进入房间，积分榜默认展示本周粉丝榜单
        if (!this.roomInfo.id) {
          this.navListActive = "3";
          this.getGiftListData({
            group: "userid",
          });
        } else {
          this.getGiftListData({
            tid: this.teacherListInfo ? this.teacherListInfo.id : "",
            userid: this.userInfo.id,
            group: "userid",
          });
        }
      }
    },
    // 获取礼物排行榜
    getGiftListData(params) {
      params.platid = 1;
      const timeData = {
        addt: this.timeToTimestamp(this.weeksTime.startDateTime), // 开始时间
        addtend: this.timeToTimestamp(this.weeksTime.endDateTime), // 结束时间
      };

      getGiftList(
        Object.assign({}, params, this.s_list_active === "3" ? {} : timeData)
      ).then((res) => {
        if (res.Status === 0) {
          this.teacherFanLists = res.content;
          this.userRanking = res.userph;
          if (this.navListActive === "1") {
            this.teacherFanList = [];
            res.content.forEach((item, index) => {
              if (index !== 0 && index !== 1 && index !== 2 && index <= 9) {
                item.indexs = index + 1;
                this.teacherFanList.push(item);
              }
            });
          }
        }
      });
    },
    // 获取礼物列表
    getGiftData() {
      getPrezzies({
        platid: 1,
      }).then((res) => {
        if (res.Status === 0) {
          this.giftList = res.content;
        }
      });
    },
    // 日期转时间戳
    timeToTimestamp(time) {
      let timestamp = Date.parse(new Date(time).toString());
      return timestamp;
    },

    // 初始化页面
    async pageInit() {
      // 获取禁言列表参数数据
      await this.$store.dispatch("home/getDicts", { name: "sys_forbid_time" });
      await this.$store.dispatch("home/getDicts", {
        name: "sys_forbid_reason",
      });
      // 获取普通用户是否有@权限
      await this.$store.dispatch("home/getDicts", {
        name: "talk_set",
        mark: 1,
      });
      // 进入页面获取房间列表
      await this.getRoomList();
      // craig--进入页面获取游客是否可以发言
      await this.isguestmessageMethod();
      // craig-- 获取课堂笔记列表
      await this.getBnotes();
      // 判断房间信息和用户信息是否已经存在，存在就直接更改当前房间的视频地址
      if (this.userInfo.id && this.roomInfo.id) {
        // 获取选中房间的视频流地址或者默认视频地址 设置视频流地址或者默认播放视频 isplay: true 直播中（设置视频流地址）  false 不在直播（设置默认视频地址）
        // 获取当前房间的信息
        await this.getRoomInfo(this.roomInfo);
        this.$store.commit("home/set_isplay", this.roomInfo.isplay);
        if (this.roomInfo.isplay) {
          this.$store.commit("home/set_ckplayerObject", {
            video: this.roomInfo.param.pcurl,
            poster: "",
            live: true,
          });
          // eslint-disable-next-line no-undef
          this.$store.commit(
            "home/set_player",
            new Ckplayer(this.ckplayerObject)
          );
          // vue-video-player插件，暂时废弃该方法
          // this.$store.commit('home/set_videoPlayerConfig', { sources: [{ src: this.roomInfo.param.pcurl, type: 'application/x-mpegURL' }], poster: '' })
        } else {
          // 获取默认视频信息(测试环境发现有些房间的默认播放视频的id为空，所以加了判断)
          this.roomInfo.param.efile_id
            ? await this.$store.dispatch("home/getRoomDefaultVideoInfo", {
                id: this.roomInfo.param.efile_id,
                type: 0,
              })
            : "";
        }
        // 连接socket
        const params = {
          roomid: this.roomInfo.id,
          uid: this.userInfo.id,
        };
        this.$store.dispatch("home/socketInit", params);
        // 获取房间在线用户列表
        await this.$store.dispatch("home/getCurrentRoomUser", {
          broomid: this.roomInfo.id,
        });
        // this.roomInfo.id ? this.onPlayerPlay(this.$refs['videoPlayer'].player) : ''
      }
    },
    // 登录成功后处理事件(登录成功后循环获取房间信息)
    handleLoginSuccess() {
      const list = Object.assign([], this.roomList);
      list.length &&
        list.forEach(async (item) => {
          await this.getRoomInfo(item);
        });
      this.$store.commit("home/set_roomList", list);
      this.pageInit();
    },
    // 获取房间列表
    async getRoomList() {
      const option = {
        platid: config.platid,
        page: 0,
        size: 10,
      };
      await this.$store.dispatch("home/getRoomList", option);
    },
    // craig--获取老师和课程信息
    async getTeacherInfo() {
      await this.$store.dispatch("home/getTeacherInfo", {
        broomid: this.roomInfo.id,
      });
    },
    // craig--获取课堂笔记列表
    async getBnotes() {
      const option = {
        broomid: this.roomInfo.id,
        tid: this.teacherInfo && this.teacherInfo[1].id,
        bsheetid: this.teacherInfo && this.teacherInfo[0].id,
        sort: "ut,DESC",
      };
      await this.$store.dispatch("home/getBnotes", option);
    },
    // craig--获取登录提示时间间隔
    async loginSetTime() {
      await this.$store.dispatch("home/loginSetTime", {
        platid: config.platid,
      });
    },
    // craig--根据登录提示时间间隔和次数控制弹框
    craigSetTime() {
      if (this.tipTime.minute) {
        let minute = Number(this.tipTime.minute);
        this.timer = setTimeout(() => {
          if (Number(this.tipTime.num) > 0) {
            this.$store.commit("home/set_dialogobj4", {
              dialogVisible: true,
              isCloseIcon: true,
              width: "500px",
              gq: true,
            });
          } else if (Number(this.tipTime.num) == 0) {
            this.$store.commit("home/set_dialogobj4", {
              dialogVisible: true,
              isCloseIcon: false,
              width: "500px",
              gq: false,
            });
            clearInterval(this.timer);
          }
        }, minute * 60 * 1000);
      }
    },
    // craig--判断游客是否可以发言
    async isguestmessageMethod() {
      await this.$store.dispatch("home/isguestmessage", {
        platid: config.platid,
      });
    },
    // 获取房间信息
    async getRoomInfo(item) {
      const option = {
        userId: this.userInfo.id || "",
        "broom.id": item.id,
        "bclass.type": 1,
      };
      await this.$store.dispatch("home/getRoomInfo", option).then((res) => {
        if (res.Status === 0) {
          res.liveStatus === "1" ? (item.isplay = true) : (item.isplay = false); // true直播中 false非直播中
          item.regStatus = res.regStatus || ""; // 1可以报名 2 报名过期 3 已报名
          item.tId = res.tId || ""; // 当前直播中老师的id
          item.classId = res.classId || ""; // 可报名课程的id
          item.onLineNum = res.onLineNum || 0; // 在线人数
          item.regNum = res.regNum || ""; // 报名人数
        }
      });
    },
    // 发言更多操作
    handleEvent(type, item) {
      // type:1、回复引用 2、禁言 3、私聊 4、撤回发言 5、@别人
      switch (type) {
        case 1:
          this.sendType = 1;
          this.$refs["inputSection"].focus();
          this.handleReplyEvent(item);
          break;
        case 2:
          this.handleStopEvent(item);
          break;
        case 3:
          this.handlePrivateChatEvent(2, item, false, true);
          break;
        case 4:
          this.handleRecallEvent(item);
          break;
        case 5:
          this.sendType = 5;
          this.$refs["inputSection"].focus();
          this.handleOtherPeopleEvent(item);
          break;
        default:
          this.sendType = 0;
          break;
      }
    },
    // 回复引用
    handleReplyEvent(item) {
      this.replyCall = {
        show: true,
        name: item.sname,
        txt: item.param.TXT,
      };

      const nick = String(this.userInfo.mtaccr).replace(
        String(this.userInfo.mtaccr).substring(
          2,
          String(this.userInfo.mtaccr).length - 2
        ),
        "****"
      );
      this.sendData = {
        broomid: this.roomInfo.id,
        sname: this.userInfo.nick || nick,
        sid: this.userInfo.id,
        rid: "",
        rname: "",
        type: 0,
        param: {
          TXT: this.sendTxt,
          CITE_TXT: item.param.TXT,
          CITE_TXT_ID: item.id,
          CITE_TXT_NAME: item.sname,
        },
      };
    },
    // 关闭回复引用
    handleCloseReplyEvent() {
      this.replyCall = {
        show: false,
        name: "",
        txt: "",
      };
      const nick = String(this.userInfo.mtaccr).replace(
        String(this.userInfo.mtaccr).substring(
          2,
          String(this.userInfo.mtaccr).length - 2
        ),
        "****"
      );
      this.sendData = {
        broomid: this.roomInfo.id,
        sname: this.userInfo.nick || nick,
        sid: this.userInfo.id,
        rid: "",
        rname: "",
        type: 0,
        param: {
          TXT: "",
          CITE_TXT: "",
          CITE_TXT_ID: "",
          CITE_TXT_NAME: "",
        },
      };
    },
    // 禁言
    handleStopEvent(item) {
      this.$store.commit("home/set_stopWordsUserInfo", item);
      this.$store.commit("home/set_dialogobj", {
        dialogVisible: true,
        isCloseIcon: true,
        type: 2,
        width: "500px",
      });
    },
    // 撤回发言
    handleRecallEvent(item) {
      const option = {
        broomid: this.roomInfo.id,
        type: "12",
        id: item.id,
        sid: this.userInfo.id,
      };
      this.socket.send(JSON.stringify(option));
    },
    // @别人
    handleOtherPeopleEvent(item) {
      this.sendTxt = "@" + item.sname;
      const nick = String(this.userInfo.mtaccr).replace(
        String(this.userInfo.mtaccr).substring(
          2,
          String(this.userInfo.mtaccr).length - 2
        ),
        "****"
      );
      this.sendData = {
        broomid: this.roomInfo.id,
        sname: this.userInfo.nick || nick,
        sid: this.userInfo.id,
        rid: item.sid,
        rname: item.sname,
        type: 0,
        param: {
          TXT: this.sendTxt,
          CITE_TXT: "",
          CITE_TXT_ID: "",
          CITE_TXT_NAME: "",
        },
      };
    },
    onPlayerPlay(player) {
      const myPlayer = this.$refs.videoPlayer.player;
      myPlayer.play();
      this.$message({
        type: "warning",
        message: "请您自行打开视频声音",
      });
    },
    onPlayerPause(event) {},
    onPlayerEnded(event) {},
    // 房间切换按钮
    async handleRoomList() {
      await this.getRoomList();
      this.roomInfo.id
        ? this.$store.commit("home/set_roomtid", this.roomInfo.tId)
        : "";
      this.$store.commit("home/set_showroomlist", true);
    },
    // 用户列表切换按钮
    async handleUserList() {
      if (!this.userInfo.id) {
        this.$message({
          type: "error",
          message: "请您登录，并选择房间后再查看用户列表",
        });
        return false;
      } else {
        if (!this.roomInfo.id) {
          this.$message({
            type: "error",
            message: "请您选择房间后再查看用户列表",
          });
          return false;
        }
      }
      // 获取当前房间的在线用户
      await this.$store.dispatch("home/getCurrentRoomUser", {
        broomid: this.roomInfo.id,
      });
      this.$store.commit("home/set_showuserlist", !this.showUserList);
      if (!this.showUserList) {
        this.$store.commit("home/set_currentroomuserlist", []);
      }
    },
    /**
     * 私聊弹出窗触发点（1、客服点击页面私聊按钮 2、客服在聊天记录中点击私聊 3、socket接收到私聊信息 4、用户列表触发私聊）
     * 此处只处理情况1和2(情况4与情况2的处理逻辑想通，只是触发是在用户列表中)，情况3放到socket中处理
     * 情况1：先请求当前客服的私聊用户列表 1-1：私聊用户列表为空，不打开私聊窗口，提示客服到聊天记录中选择一个用户去私聊；1-2私聊用户列表不为空，默认当前私聊用户信息为列表第一个用户,并请求和第一个用户的聊天记录。打开私聊窗口
     * 情况2：先请求当前客服私聊用户列表 2-1：私聊用户列表为空，将当前选中的私聊用户添加到列表中，并同时修改当前私聊用户信息为该选中用户；2-2私聊用户列表不为空，将当前用户添加到列表第一个，并修改当前默认私聊用户信息为该选中用户以及修改状态机中私聊用户列表，请求和该用户的私聊聊天记录。以上两种都需要最后打开私聊窗口（注意这种情况下需要去重处理）
     * type: 1、客服点击页面私聊按钮 2、用户在聊天记录中点击用户私聊
     * info: 选中用户的信息{ id: '', nick: ''}
     * **/
    async handlePrivateChatEvent(type, info, bool, isFalse) {
      console.log(type, info, bool, isFalse);
      if (!isFalse) {
        // 普通用户业务逻辑
        const option = {
          broomid: this.roomInfo.id,
          id: this.userInfo.id,
          platid: 1,
        };
        // 获取分配的客服
        await this.$store.dispatch("home/getDistributecustomer", option);
        // 请求和选中私聊用户的聊天记录
        if (this.distributecustomer.id) {
          const params = {
            broomid: this.roomInfo.id,
            type: 11,
            id1: this.userInfo.id,
            id2: this.distributecustomer.id,
            page: 0,
            size: 50,
          };
          await this.$store.dispatch(
            "home/getUserPrivateChatHistoryListById",
            params
          );
          // 打开私聊窗口
          this.$store.commit("home/set_dialogobj", {
            dialogVisible: true,
            isCloseIcon: false,
            type: 3,
            width: "650px",
          });
        }
      } else {
        // 客服和讲师的业务逻辑
        this.$store.commit("home/set_ishomePrivateBtn", bool);
        if (!this.userInfo.id) {
          this.$message({
            type: "error",
            message: "请您登录，并选择房间后再查看私聊信息",
          });
          return false;
        }
        // 请求当前客服或者老师的私聊用户列表
        const option = {
          id: this.userInfo.id, // 当前登录老师或者客服id
          broomid: this.roomInfo.id, // 当前所在房间id
        };
        await this.$store.dispatch("home/getUserListByManagerId", option);
        const list = Object.assign([], this.privateUserList);
        // 根据不同情况进行判断并处理对应逻辑
        if (type === 1) {
          if (list.length) {
            // 设置状态机当前私聊用户信息
            this.$store.commit("home/set_privateUserInfo", list[0]);
            // 请求和选中私聊用户的聊天记录
            const params = {
              broomid: this.roomInfo.id,
              type: 11,
              id1: this.userInfo.id,
              id2: list[0].id,
              page: 0,
              size: 50,
            };
            await this.$store.dispatch(
              "home/getUserPrivateChatHistoryListById",
              params
            );
            // 打开私聊窗口
            this.$store.commit("home/set_dialogobj", {
              dialogVisible: true,
              isCloseIcon: false,
              type: 3,
              width: "890px",
            });
          }
        } else {
          let sid = 0;
          let sname = '';

          if (info && info.sid) {
            sid = info.sid;
          } else if (info && info.userid) {
            sid = info.userid;
          } else {
            sid = list[0].id;
          }

          if (info && info.sname) {
            sname = info.sname;
          } else if (info && info.nick) {
            sname = info.nick;
          } else {
            sname = list[0].nick;
          }

          if (list.length) {
            // 用户列表不为空，要去重，先检查列表中有没有该用户，如果有就先移除，然后把当前用户添加进去；如果没有直接添加进去
            const regList = [];
            list.forEach((item) => {
              regList.push(item.id);
            });
            if (regList.indexOf(sid) > -1) {
              list.splice(regList.indexOf(sid), 1);
            }
            list.unshift({ id: sid, nick: sname, ...info });
          } else {
            list.push({ id: sid, nick: sname, ...info });
          }
          // 设置私聊用户列表
          this.$store.commit("home/set_privateUserList", list);
          // 设置状态机当前私聊用户信息
          this.$store.commit("home/set_privateUserInfo", {
            id: sid,
            nick: sname,
            ...info
          });
          // 请求和选中私聊用户的聊天记录
          const params = {
            broomid: this.roomInfo.id,
            type: 11,
            id1: this.userInfo.id,
            id2: sid,
            page: 0,
            size: 50,
          };
          await this.$store.dispatch(
            "home/getUserPrivateChatHistoryListById",
            params
          );
          // 打开私聊窗口
          this.$store.commit("home/set_dialogobj", {
            dialogVisible: true,
            isCloseIcon: false,
            type: 3,
            width: "890px",
          });
        }
      }
    },
    // 发送事件处理(点击发送按钮)
    handleSendEvent() {
      if (this.userInfo.id == "") {
        this.$message({
          type: "error",
          message: "请先登录后发言",
        });
        return false;
      }
      if (!this.userInfo.id && !this.roomInfo.id) {
        this.$message({
          type: "error",
          message: "请先登录并进入房间",
        });
        return false;
      }
      const TXT = this.sendTxt.replace(/\s*/g, "");

      if (TXT === "") {
        this.$message({
          showClose: true,
          message: "发送内容不能为空",
          type: "error",
        });
        return false;
      } else if (TXT.length > this.roomset.content_lenth) {
        this.$message({
          showClose: true,
          type: "error",
          message: `输入内容长度不能超过${this.roomset.content_lenth}`,
        });
        return false;
      }
      let option = {};
      if (this.sendType === 0) {
        const nick = String(this.userInfo.mtaccr).replace(
          String(this.userInfo.mtaccr).substring(
            2,
            String(this.userInfo.mtaccr).length - 2
          ),
          "****"
        );
        var headerimg = null;
        //utype --> 0:普通用户 1:讲师
        if (this.userInfo.utype == 0 || this.userInfo.utype == undefined) {
          if (sessionStorage.getItem("headerImg")) {
            headerimg = sessionStorage.getItem("headerImg");
          } else {
            headerimg = this.imgSrc;
          }
        } else {
          headerimg = this.userInfo.avatar;
        }
        option = {
          broomid: this.roomInfo.id,
          sname: this.userInfo.whoami || this.userInfo.nick || nick,
          sid: this.userInfo.id,
          type: 0,
          platid: config.platid,
          param: {
            TXT: this.sendTxt.replace("@" + this.sendData.rname, ""),
          },
          headerimg,
          smtaccr: this.userInfo.mtaccr,
          msgtype: "msg",
        };
        option = Object.assign({}, option);
      } else {
        const nick = String(this.userInfo.mtaccr).replace(
          String(this.userInfo.mtaccr).substring(
            2,
            String(this.userInfo.mtaccr).length - 2
          ),
          "****"
        );
        var headerimg = null;
        //utype --> 0:普通用户 1:讲师
        if (this.userInfo.utype == 0 || this.userInfo.utype == undefined) {
          if (sessionStorage.getItem("headerImg")) {
            headerimg = sessionStorage.getItem("headerImg");
          } else {
            headerimg = this.imgSrc;
          }
        } else {
          headerimg = this.userInfo.avatar;
        }
        option = {
          broomid: this.roomInfo.id,
          sname: this.userInfo.whoami || this.userInfo.nick || nick,
          sid: this.userInfo.id,
          type: 0,
          platid: config.platid,
          param: {
            TXT: this.sendTxt.replace("@" + this.sendData.rname, ""),
            CITE_TXT: this.sendData.param.CITE_TXT,
            CITE_TXT_ID: this.sendData.param.CITE_TXT_ID,
            CITE_TXT_NAME: this.sendData.param.CITE_TXT_NAME,
          },
          headerimg,
          smtaccr: this.userInfo.mtaccr,
          msgtype: "msg",
        };
        option = Object.assign({}, this.sendData, option);
      }
      this.socket.send(JSON.stringify(option));
      this.sendTxt = "";
      this.replyCall = {
        show: false,
        name: "",
        txt: "",
      };
      this.sendType = 0;
    },
    // 关闭弹出层
    handleCloseDialog() {
      this.$store.commit("home/set_dialogobj", {
        dialogVisible: false,
        isCloseIcon: true,
      });
    },
    handleCloseDialog4() {
      this.$store.commit("home/set_dialogobj4", {
        dialogVisible: false,
        isCloseIcon: true,
      });
      let num = Number(this.tipTime.num);
      let data = {
        minute: this.tipTime.minute,
        num: num - 1,
      };
      this.$store.commit("home/set_tipTime", data);
    },
    // 关闭弹出层
    handleCloseDialog3() {
      this.$store.commit("home/set_dialogobj3", {
        dialogVisible: false,
        isCloseIcon: true,
      });
    },
    // 点击空表部分隐藏用户列表和房间列表
    handleGolbalEvent() {
      // this.$store.commit('home/set_showroomlist', false)
      this.$store.commit("home/set_showuserlist", false);
    },
    // 公聊屏幕滚动和冻结
    handleAutoScroll(bool) {
      this.$store.commit("home/set_isatuoscroll", bool);
    },
    // 关闭注册成功之后的dialog
    closerDialog() {
      this.craigDialog.registerVisible = false;
      this.$store.dispatch("login/handleLogin", this.registerAfterInfo);
      this.$store.commit("login/set_registerAfterInfo", "");
      clearInterval(this.timer);
    },
    // 点击选择器
    selectShow(attr) {
      if (!this.userInfo.id && !this.roomInfo.id) {
        this.$message({
          type: "error",
          message: "请先登录并进入房间",
        });
        return false;
      }
      this.controlClass = attr;
      this.$store.commit("home/set_selectTree", attr);
    },
    // 跳转登录
    handleLogin(attr) {
      this.$store.commit("home/set_dialogobj", {
        dialogVisible: true,
        isCloseIcon: false,
        type: 1,
        width: "500px",
      }),
        this.$store.commit("home/set_dialogobj4", {
          dialogVisible: false,
          isCloseIcon: true,
        });
      if (attr === 1) {
        this.$store.commit("login/set_activeName", "second");
      } else if (attr === 2) {
        this.$store.commit("login/set_activeName", "first");
      } else {
        this.$store.commit("login/set_activeName", "second");
        this.$store.commit("home/set_dialogobj", {
          dialogVisible: true,
          isCloseIcon: true,
          type: 1,
          width: "500px",
        });
      }
    },
    // 跳转讲师助理
    live800() {
      // this.liveBody = !this.liveBody;
      console.log("我的信息", this.userInfo);

      // 点击讲师助理按钮后隐藏消息提示
      this.$store.commit("home/set_showRed", false);
      this.$store.commit("home/set_customMsgTotal", 0);
      if (this.userInfo.utype == 0) {
        this.handlePrivateChatEvent(1, {}, true, false);
      } else {
        this.handlePrivateChatEvent(1, {}, true, true);
      }
    },
    // 客服私聊 打开聊天窗口
    openChat(info) {
      // this.liveBody = !this.liveBody;
      console.log("我的信息", this.userInfo);

      // 点击在线用户列表 聊天按钮 后隐藏消息提示
      this.$store.commit("home/set_showRed", false);
      this.$store.commit("home/set_customMsgTotal", 0);
      this.handlePrivateChatEvent(2, info, true, true);
    },
    jumpLive() {
      window.open(this.$parent.live800UrlUc);
    },
    shadeMenu() {
      this.$store.commit("home/set_rightMenu", false);
      this.$store.commit("home/set_timeTable", false);
      this.$store.commit("home/set_noSpeak", false);
    },
    closerCode() {
      this.qrcodeBox = false;
    },
    // 关闭vip房间弹窗
    cancel_vip() {
      this.$store.commit("home/set_isVip", false);
    },
    // 确认vip房间弹窗
    confirm_vip() {
      console.log(this.pwd);
      const data = {
        pwd: this.pwd,
        roomid: this.vipRoom.id,
      };
      this.$store.dispatch("home/verifypwd", data);
    },
    // 控制侧边栏显示与隐藏
    controlSlider() {
      this.$store.commit("home/set_packOrDevelop", false);
    },
    // 鼠标划入video
    mouseOver() {
      this.noticeControl = true;
    },
    // 鼠标划出video
    mouseLeave() {
      this.noticeControl = false;
    },
    // 点击显示公告按钮
    clickNotice() {
      if (!this.roomInfo.id) {
        this.$message({
          type: "warning",
          message: "请先进入房间!",
        });
        return;
      }
      this.noticeTextControl = true;
    },
    // 关闭文字公告内容
    cancelNoticeText() {
      this.noticeTextControl = false;
    },
    // 时间戳转时间格式
    timestampToTime(timestamp) {
      let date = new Date(parseInt(timestamp));
      let Year = date.getFullYear();
      let Moth =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let Hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let Minute =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let Sechond =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Hour + ":" + Minute;
    },
    // 处理只显示十个字符，其他的用...代替
    getNewName(name) {
      var len = 0;
      var reallen = 0;
      for (var j = 0; j < name.length; j++) {
        if (name.charCodeAt(j) > 127 || name.charCodeAt(j) == 94) {
          len += 1;
        } else {
          len++;
        }
        if (len < 100) {
          reallen++;
        }
      }
      if (len >= 100) {
        name = name.substr(0, reallen) + "···";
      }
      return name;
    },
    // 刷新video
    reloadVideo() {
      // this.$store.commit('home/set_isReload',!this.isReload);
      // document.getElementById('ckplayerVideo').children[0].children[0].load();
      // this.$store.commit('home/set_ckplayerObject',{video: this.roomInfo.param.pcurl})
      // console.log('重新给直播流赋值');
      location.reload();
    },
    // 时间戳转时间
    getDateChange(date) {
      var t = new Date(date).toLocaleString();
      return t;
    },
    // 展示老师讲义第一条详情
    showDialog() {
      this.teacherVisible = !this.teacherVisible;
    },
    // 点击shadow关闭房间列表和课程表
    changeShadow() {
      this.$store.commit("home/set_showroomlist", false);
    },
  },
};
</script>

<style lang="less">
@keyframes jumping {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}

.bigBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .rightMenu {
    width: 275px;
    height: 100%;
  }
  .develop {
    width: 10px;
    height: 100%;
    background-color: #fff;
    position: relative;
    img {
      position: absolute;
      top: 46%;
      right: -35px;
      transform: translate(-50%, 0);
      cursor: pointer;
    }
  }
  .home-page {
    flex: 1;
    // width: 88%;
    background: #f0f0f0;
    box-sizing: border-box;
    overflow: hidden;
    /**主内容区域**/
    .home-page-main {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      // margin-top: 30px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      padding: 0;
      padding-left: 15px;
      .main-header {
        // height: 50px;
        // line-height: 50px;
      }
    }
    /**主内容区域左边**/
    .video-section {
      flex: 1;
      position: relative;
      overflow: hidden;
      // min-width: 1100px;
      // margin-right: 10px;
      .video-header {
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0px;
        box-sizing: border-box;
        // padding: 5px 0;
        .changeBox {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 120px;
          .room-change-btn {
            width: 90px;
            cursor: pointer;
          }
        }
      }
      .video-sec {
        width: 100%;
        height: 660px;
        cursor: pointer;
      }
    }
    /**主内容区域右边**/
    .chat-container {
      // float: right;
      width: 500px;
      height: auto;
      min-width: 300px;
      border-top: 1px solid #ededed;
      position: relative;
      .chat-header {
        background: #ffffff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        .chat-header-left {
          .user-list-btn,
          .user-list-up-btn {
            display: inline-block;
            width: 16px;
            height: auto;
            cursor: pointer;
            margin: 17px 0 0 20px;
          }
        }
        .chat-header-right {
          width: 100%;
          position: relative;
          flex: 1;
          text-align: center;
          font-size: 16px;
          height: 55px;
          line-height: 55px;
          background-color: #ececec;
          .chat-header-line {
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            margin: auto;
            width: 104px;
            height: 2px;
            background: #3498db;
          }
          span {
            display: inline-block;
            width: 50%;
            height: 100%;
            cursor: pointer;
          }
          .currentSpan {
            background-color: #fff;
            color: #2faef8;
            border-top: 2px solid #2faef8;
          }
        }
        .liveTit {
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding: 0 10px;
          img {
            cursor: pointer;
            width: 10px;
          }
        }
        .chat-header-living {
          width: 49px;
          height: 49px;
          text-align: center;
          line-height: 49px;
          cursor: pointer;
          img {
            width: 30px;
            height: 30px;
            margin-top: 8px;
          }
        }
      }
    }
    /**播放器**/
    .video-main {
      width: 100%;
      height: 660px;
      position: relative;
      border-right: 1px solid #eff3f5;
      background: #000000;
      .notice {
        width: 100%;
        height: 45px;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        .noticeSpan {
          display: inline-block;
          width: 72px;
          height: 24px;
          color: #d1d1d1;
          border-radius: 3px;
          border: 1px solid #656565;
          text-align: center;
          line-height: 24px;
          float: right;
          margin: 10px 20px 0 0;
          cursor: pointer;
        }
        .noticeSpan:hover {
          background-color: #f34461;
          color: #fff;
          border: none;
          border: 1px solid #f34461;
        }
        .noticeText {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding: 0 18px;
          .noticeText_p {
            width: 94%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #fff;
            label {
              width: auto;
              cursor: pointer;
            }
          }
          img {
            width: 20px;
          }
        }
      }
      .videoSlider {
        width: 90px;
        height: 75px;
        background-color: rgba(87, 86, 86, 0.5);
        position: absolute;
        top: 50%;
        right: -45px;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        span {
          color: #d9d9d9;
          line-height: 35px;
          cursor: pointer;
        }
        label {
          width: 100%;
          height: 1px;
          background-color: #d9d9d9;
        }
      }
      .codeBox {
        position: absolute;
        bottom: 139px;
        left: 20px;
        box-sizing: border-box;
        padding: 5px;
        background-color: #fa4440;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        .qrcode {
          width: 75px;
        }
        span {
          font-size: 12px;
          color: #fff;
          margin-top: 5px;
        }
        span:nth-child(3) {
          margin-top: 0;
          margin-bottom: 5px;
        }
        .roundCloser {
          position: absolute;
          top: -10px;
          right: -28px;
          cursor: pointer;
        }
      }
    }
    /**房间列表**/
    .room-list {
      position: fixed;
      top: 110px;
      left: 267px;
      overflow: hidden;
      z-index: 999;
      display: flex;
      justify-content: center;
      // align-items: center;
    }
    /**聊天区域**/
    .chat-main {
      position: relative;
      height: auto;
      border-left: 1px solid #eff3f5;
      box-shadow: 5px 5px 5px #c3c6c8;
    }
    /**用户列表**/
    .user-list {
      position: absolute;
      top: -1px;
      left: 0;
      z-index: 3;
    }
    /**聊天区域**/
    .chat-section {
      height: 659px;
      background-color: #f7f7f7;
      box-sizing: border-box;
      padding: 10px 3px;
      .chatListBox {
        width: 100%;
        height: 100%;
      }
    }
    .chat-live {
      height: 600px;
      background-color: #f7f7f7;
    }
    .chat-handler {
      height: 35px;
      box-sizing: border-box;
      padding: 0 8px;
      background-color: #fff;
      .scroll-control {
        height: 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        img {
          width: 20px;
          height: auto;
          cursor: pointer;
          margin-right: 8px;
        }
        .newLiveBox {
          display: flex;
          justify-content: center;
          align-items: center;
          .jumpCustom {
            width: 72px;
            height: 25px;
            margin-right: 10px;
            background-color: #ec808d;
            border-radius: 2px;
            color: #fff;
            font-size: 14px;
            text-align: center;
            line-height: 25px;
            cursor: pointer;
          }
          p {
            width: 72px;
            height: 25px;
            background-color: #f4aa17;
            border-radius: 2px;
            color: #fff;
            font-size: 14px;
            text-align: center;
            line-height: 25px;
            position: relative;
            margin-right: 10px;
            cursor: pointer;
            span {
              position: absolute;
              top: -8px;
              right: -8px;
              min-width: 16px;
              height: 16px;
              line-height: 16px;
              background-color: #f41717;
              border-radius: 8px;
              color: #ffffff;
              font-size: 12px;
              text-align: center;
            }
            .jumping-text {
              animation: jumping 0.5s infinite;
            }
          }
          .jumpLive {
            width: 72px;
            height: 25px;
            background-color: #158aea;
            border-radius: 2px;
            color: #fff;
            font-size: 14px;
            text-align: center;
            line-height: 25px;
            cursor: pointer;
          }
        }
        ul {
          width: 80px;
          position: absolute;
          bottom: 35px;
          left: -8px;
          background-color: #fff;
          box-shadow: 1px 1px 10px #bfc0c4;
          border-radius: 3px;
          li {
            font-size: 12px;
            border-bottom: 1px solid #f1f1f1;
            box-sizing: border-box;
            padding: 5px 0 5px 10px;
            color: #666666;
            cursor: pointer;
          }
          li:last-child {
            border-bottom: none;
          }
          .liRed {
            color: #ee2547;
          }
        }
      }
    }
    /**输入区域**/
    .send-section {
      position: relative;
      height: 84px;
      border-top: 1px solid #eeeeee;
      background-color: #fff;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      // margin-top: 15px;
      .send-input {
        height: 100%;
        width: 100%;
        textarea {
          width: 100%;
          height: 45px;
          margin: 0 auto;
          border: none;
          outline: none;
          resize: none;
          box-sizing: border-box;
          padding: 5px 20px 5px 10px;
        }
        .textareaTip {
          cursor: pointer;
          position: absolute;
          top: 35%;
          left: 50%;
          transform: translate(-50%, -75%);
          color: #a7a4a4;
          font-size: 14px;
        }
        .send-btn {
          height: 30px;
          text-align: right;
          // position: absolute;
          // top: 0px;
          // right: 7px;
          margin-top: 0px;
          button {
            width: 60px;
            height: 25px;
            line-height: 25px;
            font-size: 12px;
            color: #ffffff;
            background: #158aea;
            border: none;
            border-radius: 5px;
            margin-right: 10px;
            // border-top-right-radius: 6px;
            // border-bottom-right-radius: 6px;
          }
          span {
            color: #c6c6c6;
            font-size: 12px;
            margin-right: 10px;
          }
        }
      }
    }
    /**回复引用展示区域**/
    .reply-section {
      position: absolute;
      bottom: 110px;
      left: 0;
      width: 92%;
      font-size: 12px;
      padding: 10px 15px;
      background: #fbf5ee;
      span {
        color: #8c99a5;
      }
      p {
        max-width: 410px;
        color: #8c99a5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .class-reply-btn {
        position: absolute;
        top: 2px;
        right: 2px;
        width: 15px;
        height: 15px;
        background: #8c99a5 url("./assets/images/private-close-icon.png")
          no-repeat center center;
        background-size: 8px 8px;
        cursor: pointer;
        border-radius: 50%;
      }
    }
    /*弹窗**/
    .dialog-close {
      position: absolute;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      top: -48px;
      right: -55px;
      background: url("./assets/images/dialog-close-icon.png") no-repeat center
        center;
      background-size: contain;
      cursor: pointer;
    }

    /**房间列表和用户列表显示隐藏动画**/
    .fade-enter-active,
    .fade-leave-active {
      // transition: top 0.3s;
    }
    .fade-enter,
    .fade-leave-active {
      top: -1000px;
    }
    .video-js .vjs-big-play-button {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      height: 3em;
      line-height: 3em;
      border-radius: 1.5em;
    }
    .vjs_video_3-dimensions.vjs-fluid {
      padding-top: 700px;
    }
    .video-player,
    .vjs-custom-skin {
      height: 100%;
    }
    .video-js.vjs-fluid,
    .video-js.vjs-16-9,
    .video-js.vjs-4-3 {
      height: 100%;
    }
    .vjs-tech {
      max-height: 700px !important;
    }
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
      padding-bottom: 15px;
    }
    .registerBox {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .registerSuccess {
        margin: 30px auto;
      }
      .registerCenter {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .registerCenterLeft {
          width: 40%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 10px;
            background-color: #d0081d;
            margin-bottom: 5px;
            img {
              width: 115px;
            }
            span {
              color: #fff;
              margin-top: 10px;
            }
          }
          button {
            width: 130px;
            height: 30px;
            border: 1px solid #8f9091;
            background: none;
            border-radius: 12px;
            margin-top: 10px;
          }
        }
        .registerCenterRight {
          width: 60%;
          height: 300px;
          box-sizing: border-box;
          padding-right: 25px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          line-height: 24px;
          p {
            width: 240px;
            height: 80px;
            background-color: #f7f7f7;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            box-sizing: border-box;
            padding-left: 20px;
            margin: 10px 0;
            span {
              color: #000000;
              font-size: 17px;
              font-weight: 510;
            }
          }
        }
      }
      .registerButton {
        width: 85%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        p {
          width: 190px;
          height: 45px;
          border: 1px solid #ee2547;
          color: #ee2547;
          border-radius: 7px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        p:nth-child(2) {
          background-color: #ee2547;
          color: #fff;
        }
      }
    }
    .dialog-craigNew {
      .craigNewP {
        width: 100%;
        height: 80px;
        background-color: #2c2c2c;
        color: #ffffff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        box-sizing: border-box;
        font-size: 20px;
      }
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 15px 0 20px;
        h3 {
          color: #000000;
          font-size: 20px;
          span {
            color: #ee2547;
          }
        }
        p {
          margin-bottom: 20px;
          font-size: 17px;
        }
        button {
          width: 80%;
          height: 55px;
          border: 1px solid #ee2547;
          background: none;
          color: #ee2547;
          border-radius: 5px;
          margin-top: 15px;
          font-size: 17px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 50px;
            margin-right: 5px;
          }
        }
        button:nth-child(4) {
          background-color: #ee2547;
          color: #fff;
        }
      }
    }
    .shade {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 5;
      background-color: rgba(20, 20, 20, 0.4);
    }
    .timetable {
      width: 55%;
      height: 700px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
    }
    .noSpeak {
      width: 40%;
      height: 400px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
    }
    .vipBox {
      width: 515px;
      height: 370px;
      background-color: #ffffff;
      position: fixed;
      z-index: 999;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding-bottom: 30px;
      img {
        width: 100%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
      div {
        width: 80%;
        margin: 0 auto;
        span {
          font-size: 18px;
        }
        input {
          width: 99%;
          height: 52px;
          border: 1px solid #e2e2e2;
          border-radius: 6px;
          text-indent: 15px;
          margin-top: 15px;
        }
      }
      p {
        width: 57%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          display: inline-block;
          width: 128px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          border-radius: 6px;
          cursor: pointer;
        }
        span:nth-child(1) {
          border: 1px solid #e2e2e2;
        }
        span:nth-child(2) {
          border: 1px solid #ee2547;
          background-color: #ee2547;
          color: #fff;
        }
      }
    }
  }
  .home-page-other {
    width: 99.5%;
    .video-main {
      // height: 755px;
    }
    .video-section {
      .video-sec {
        // height: 755px;
      }
    }
  }
  .vipBox {
    width: 515px;
    height: 370px;
    background-color: #ffffff;
    position: fixed;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 30px;
    img {
      width: 100%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
    div {
      width: 80%;
      margin: 0 auto;
      span {
        font-size: 18px;
      }
      input {
        width: 99%;
        height: 52px;
        border: 1px solid #e2e2e2;
        border-radius: 6px;
        text-indent: 15px;
        margin-top: 15px;
      }
    }
    p {
      width: 57%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        display: inline-block;
        width: 128px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        border-radius: 6px;
        cursor: pointer;
      }
      span:nth-child(1) {
        border: 1px solid #e2e2e2;
      }
      span:nth-child(2) {
        border: 1px solid #ee2547;
        background-color: #ee2547;
        color: #fff;
      }
    }
  }
  .shadow {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: rgba(138, 138, 138, 0);
    z-index: 3;
  }
  .ssDialog {
    width: 800px;
    height: 500px;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 5;
    border-radius: 5px;
    .ssContent {
      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 30px;
      white-space: pre-wrap;
      box-sizing: border-box;
      padding: 10px;
      overflow-y: auto;
      p {
        color: #000000;
      }
    }
    .controlSpeak {
      position: absolute;
      top: 10px;
      right: 20px;
      cursor: pointer;
    }
  }
}
// 3.0新添样式
.standings {
  height: 100%;
}
.s_center {
  position: relative;
  height: 100%;
  margin-left: -3px;
}
.navList {
  width: 30px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
  .navItem {
    font-size: 14px;
    color: #fff;
    width: 100%;
    min-height: 100px;
    text-align: center;
    background-color: #bdbdbd;
    writing-mode: vertical-rl;
    line-height: 30px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: 3px;
    cursor: pointer;
    &:nth-child(1) {
      height: 120px;
    }
  }
  .navItem_active {
    background-color: #ef4c5c;
  }
}
.teacherFanList {
  position: relative;
  overflow-y: auto;
  height: 100%;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
  .searchDialog {
    position: absolute;
    background-color: #fff;
    width: 460px;
    height: calc(90% - 30px);
    left: -3px;
    bottom: 0;
    z-index: 5;
    padding: 30px 20px 0;
    .teacherList {
      display: inline-block;
      width: 70px;
      padding: 0 20px 30px;
      cursor: pointer;
      text-align: center;
    }
    .teacherHeadImg {
      width: 50px;
      height: 50px;
      background-color: #8c99a5;
      border-radius: 50px;
      margin-bottom: 5px;
      display: inline-block;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }
    }
    .teacherName {
      width: 70px;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      font-size: 14px;
      color: #333333;
      text-align: center;
    }
  }
  .teacherHead {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: "18px";
    color: "#333333";
    font-weight: bold;
    padding: 20px 0 30px;
    position: relative;
    z-index: 6;
    &::before {
      position: absolute;
      left: 30px;
      content: "";
      width: 73px;
      height: 50px;
      background: url("./assets/images/standings/s_t_right.png") no-repeat
        center center;
      background-size: 100% 7px;
    }
    &::after {
      position: absolute;
      right: 30px;
      content: "";
      width: 73px;
      height: 50px;
      background: url("./assets/images/standings/s_t_left.png") no-repeat center
        center;
      background-size: 100% 7px;
    }
    .t_name {
      cursor: pointer;
      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0;
        height: 0;
        border-top: 5px solid #333333;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        margin-left: 8px;
      }
    }
    .t_head {
      width: 50px;
      height: 50px;
      background-color: #d7d7d7;
      margin: 0 10px;
      border-radius: 50px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }
    }
  }
  .s_list_tab {
    width: 350px;
    height: 36px;
    display: flex;
    background: #eceeef;
    align-items: center;
    margin: 0 auto;
    border-radius: 36px;
    overflow: hidden;
    & > div {
      width: 33.3333%;
      height: 100%;
      font-size: 18px;
      color: #666666;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
      &:nth-child(2) {
        border-radius: 36px;
      }
    }
    .itemActive {
      background: #ef4d5d;
      color: #fff;
    }
  }
  .topThree {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 16px 50px 0;
    .topOne {
      .top_head {
        margin: 0 auto -10px;
        border-radius: 52px;
        width: 52px;
        height: 52px;
        border: 5px solid #ffb14a;
        background-color: #ffb14a;
        img {
          width: 52px;
          height: 52px;
          border-radius: 52px;
        }
      }
      .fansName {
        width: 110px;
        height: 19px;
        background-color: #fce6bb;
        font-size: 14px;
        line-height: 19px;
        position: relative;
      }
    }
    .topTow {
      .top_head {
        margin: 0 auto -10px;
        border-radius: 44px;
        width: 44px;
        height: 44px;
        border: 4px solid #92a6dc;
        background-color: #92a6dc;
        img {
          width: 44px;
          height: 44px;
          border-radius: 44px;
        }
      }
      .fansName {
        width: 92px;
        height: 19px;
        background-color: #dfedf8;
        font-size: 14px;
        line-height: 19px;
        position: relative;
      }
    }
    .topsan {
      .top_head {
        margin: 0 auto -10px;
        border-radius: 44px;
        width: 44px;
        height: 44px;
        border: 4px solid #d6a288;
        background-color: #d6a288;
        img {
          width: 44px;
          height: 44px;
          border-radius: 44px;
        }
      }
      .fansName {
        width: 92px;
        height: 19px;
        background-color: #fee8dc;
        font-size: 14px;
        line-height: 19px;
        position: relative;
      }
    }
    > div {
      text-align: center;
      > .fansIntegral {
        font-size: 13px;
        margin: 5px 0;
      }
      > img {
        margin-bottom: -5px;
      }
    }
  }
}
.fansTopList {
  // height: 260px;
  margin: 20px 50px 96px;
  // overflow-y: auto;
  .fansTopListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    .fansItemLeft {
      display: flex;
      align-items: center;
    }
    .ranking {
      width: 34px;
      height: 37px;
      background: url("./assets/images/standings/ranking.png") no-repeat center
        center;
      text-align: center;
      line-height: 37px;
      font-size: 14px;
      color: #999999;
    }
    .ranking_0 {
      background: url("./assets/images/standings/one.png") no-repeat center
        center;
    }
    .ranking_1 {
      background: url("./assets/images/standings/two.png") no-repeat center
        center;
    }
    .ranking_2 {
      background: url("./assets/images/standings/three.png") no-repeat center
        center;
    }
    .fansHead {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background-color: #fcead6;
      margin: 0 9px 0 14px;
      overflow: hidden;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .fans_name {
      width: 124px;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
    }
    .fansItemRight {
      > div {
        display: inline-block;
        vertical-align: middle;
        &:nth-child(1) {
          margin-right: 15px;
          font-size: 14px;
          span {
            color: "#333333";
            margin-right: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.s_bottom {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -10px;
  background: #f5f5f5;
}
.listRules {
  font-size: 12px;
  color: #999999;
  padding: 0 15px;
  margin-bottom: 10px;
  span {
    color: #f75652;
    cursor: pointer;
  }
}
.userRanking {
  background-color: #eceeef;
  padding: 5px 0 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .userRankingLeft {
    > div {
      display: inline-block;
      vertical-align: middle;
    }
    .header {
      width: 40px;
      height: 40px;
      border: 2px solid #ced4e6;
      border-radius: 40px;
      margin-right: 10px;
      overflow: hidden;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .userInfo {
      font-size: 14px;
      color: #999999;
    }
  }
  .userRankingRight {
    font-size: 14px;
    min-width: 150px;
  }
}
.videoGiftBox {
  position: absolute;
  bottom: 52px;
  left: 0;
  width: 100%;
  background-color: #fff;
  .hideGiftView {
    width: 100%;
    height: 70px;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .userIntegralInfo {
      line-height: 70px;
      margin-left: 10px;
      > div {
        font-size: 14px;
        display: inline-block;
        margin: 0 15px;
        > span {
          margin-left: 5px;
          color: #000;
          &:nth-child(3) {
            font-weight: bold;
            color: #f83e4c;
          }
        }
        img,
        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    .giftList {
      width: 418px;
      margin-right: 185px;
      position: relative;
      .gift {
        // height: 48px;
        // border: 1px solid #d1d1d1;
        // border-radius: 5px;
        cursor: pointer;
        font-size: 0;
      }
      .swiper-button-prev,
      .swiper-button-next {
        width: 16px;
        height: 40px;
        background-color: #ff536a;
        color: #fff;
        border-radius: 3px;
        background-image: none;
        text-align: center;
        line-height: 40px;
      }
      .swiper-button-prev {
        left: -25px;
      }
      .swiper-button-next {
        right: -22px;
      }
      .swiper-button-disabled {
        opacity: 1;
        background-color: #d1d1d1;
      }
    }
  }
  .giftFixed {
    display: flex;
    align-items: flex-end;
    position: absolute;
    right: 45px;
    bottom: 8px;
    cursor: pointer;
    > div {
      &:nth-child(1) {
        margin-right: 5px;
      }
    }
  }
}
.showGiftList {
  position: absolute;
  left: -360px;
  bottom: 140px;
  min-width: 360px;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  border-radius: 38px;
  height: 38px;
  z-index: 4;
  animation: mymove 2s infinite;
  padding-right: 10px;
  .userHead {
    width: 38px;
    height: 38px;
    border-radius: 38px;
  }
  .giveGiftInfo {
    font-size: 12px;
    .giftImg {
      vertical-align: middle;
      width: 28px;
      height: 42px;
      position: relative;
      top: -5px;
      margin: 0 10px;
    }
    span {
      vertical-align: middle;
      &:nth-child(2) {
        margin: 0 5px;
      }
      &:nth-child(5) {
        color: #ef4c5c;
      }
    }
  }
}
.giftInfo {
  display: flex;
  align-items: center;
  .giftImg {
    width: 89px;
    height: 89px;
    img {
      width: 89px;
      height: 89px;
    }
  }
  .giftRight {
    margin-left: 15px;
    .giftRightName {
      font-size: 16px;
      color: #333;
      margin-bottom: 40px;
      span {
        font-size: 14px;
        color: #ef4c5c;
        margin-left: 10px;
      }
    }
    .giftRightP {
      font-size: 14px;
      color: #ef4c5c;
      margin: 10px 0;
    }
    .giftRightButton {
      width: 150px;
      height: 26px;
      background-color: #ef4c5c;
      font-size: 14px;
      color: #fff;
      text-align: center;
      line-height: 26px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
.giftImgs {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  height: 100%;
  img {
    width: 46px;
    height: 46px;
  }
}
.dialog_center {
  height: 90%;
  max-height: 566px;
  overflow: hidden;
  overflow-y: scroll;
  margin-right: 10px;
  padding: 0 30px 0 40px;
  font-size: 16px;
  color: #666;
  text-align: justify;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
  p {
    line-height: 36px;
    margin-bottom: 0;
  }
  .modal-table {
    width: 100%;
    margin: 10px 0 19px;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    border: 1px solid #dedede;
    font-size: 14px;
    color: #666;
    tr:nth-of-type(odd) {
      background-color: #fdfdfd;
    }
    tr:nth-of-type(even) {
      background-color: #f9f9f9;
    }
  }
}
@keyframes mymove {
  //滑动出来1s 停留展示1s
  0% {
    left: -360px;
    display: none;
  }
  1% {
    left: -360px;
    display: block;
  }
  50% {
    left: 30px;
  }
  99% {
    left: 30px;
  }
  100% {
    left: 30px;
    display: none;
  }
}
</style>
