<template>
  <div class="adnBox">
    <div class="animation">
      <img style="width:18px;margin-right:5px" src="../assets/images/teacherJs/voiceRed.png" alt="">
      <div class="text-container">
        <span>最新消息：</span>
        <transition class="inner-container" name="slide" mode="out-in">
          <p class="text2" :key="newsText.id" @click="link(newsText.url)">
            {{this.getNewName(newsText.val)}}
          </p>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { zhiboBanner } from '../../../../../api/info';
export default {
  name:'advertNotice',
  data(){
    return{
      news: [
      ],
      number: 0,
      imgurl:'',
    }
  },
  created(){
    this.init();
  },
  mounted(){
    this.startMove();
  },
  computed:{
    newsText() {
      return {
        id: this.number,
        val: this.news[this.number] ? this.news[this.number].name : '',
        url: this.news[this.number] ? this.news[this.number].url : '',
      }
    }
  },
  methods:{
    // 获取swiper
    init(){
      const option = {
        languageidentification:1,
        type:39,
        region:1
      }
      zhiboBanner(option).then(res=>{
        if(res.Status === 0){
          this.news = res.Data;
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    // 在定时器中不断改变计算属性对应的值
    startMove() {
      this.number = 0;
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        if (this.number < this.news.length - 1) {
          this.number += 1;
        } else {
          this.number = 0;
        }
      }, 4000);
    },
    // 跳转链接
    link(attr){
      if(attr){
        window.open(attr);
      }
    },
    // 处理只显示十个字符，其他的用...代替
    getNewName(name) {
      var len = 0;
      var reallen = 0;
      for (var j=0; j<name.length; j++) {
        if (name.charCodeAt(j)>127 || name.charCodeAt(j)==94) {
          len += 1;
        } else {
          len ++;
        }
        if(len<22){
          reallen++;
        }
      }
      if (len >= 22) {
        name = name.substr(0,reallen)+'···';
      }
      return name;
    },
  }
}
</script>

<style lang="less" scoped>
.adnBox{
  width: 100%;
  height: 38px;
  box-sizing: border-box;
  padding-left: 10px;
  .animation{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .text-container{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      span{
        color: #849090;
        margin: 0 5px;
      }
      .slide-enter-active, .slide-leave-active {
        transition: all 0.2s linear;
      }
      .slide-leave-to {
        transform: translateY(-10px);
      }
      .slide-enter {
        transform: translateY(10px);
      }
      .text2{
        color: #f81421;
        cursor: pointer;
        font-size: 15px;
      }
    }
  }
}
</style>