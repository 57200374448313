<template>
  <div class="NotLive" :class="packOrDevelop ? 'NotLive_other' : ''">
    <div v-if="control" class="smallLive">
      <img class="bannerImg" :src="imgData.imgUrl" @click="goLink(imgData.url)" alt="">
      <p>{{imgData.name}}</p>
      <img src="../assets/images/craig/noLive.png" alt="">
    </div>
    <div v-if="qrcodeBox" class="codeBox">
      <img class="qrcode" src="../assets/images/craig/code.png" alt="">
      <span>下载APP</span>
      <span>随时随地享福利</span>
      <!-- <img @click="closerCode" class="roundCloser" src="../assets/images/craig/roundCloser.png" alt=""> -->
    </div>
  </div>
</template>

<script>
import { zhiboBanner } from '../../../../../api/info';
export default {
  name:'advertNotLive',
  data(){
    return{
      imgData:null,
      control:false,
      // 主页面二维码控制
      qrcodeBox:true,
    }
  },
  computed:{
    roomInfo() {
      return this.$store.state.home.roomInfo
    },
    packOrDevelop(){
      return this.$store.state.home.packOrDevelop
    }
  },
  created(){
    this.init();
    this.isShow();
  },
  methods:{
    // 获取swiper
    init(){
      const option = {
        languageidentification:1,
        type:40,
        region:1
      }
      zhiboBanner(option).then(res=>{
        if(res.Status === 0){
          this.imgData = res.Data[0];
          this.control = true;
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    // 判断是否要显示此广告
    async isShow(){
      // 是否进入房间，没进入房间显示广告位
      if(this.$store.state.home.roomInfo.id == ''){
        this.$store.commit('home/set_controlNoLive',true);
      }else{
        // 进入房间判断是否直播，直播中，不展示广告位，无直播继续判断
        if(!this.roomInfo.isplay){
          // 是否配置默认视频
          if(this.roomInfo.param.efile_id == 0){
            // 无默认视频，展示广告位
            this.$store.commit('home/set_controlNoLive',true);
          }else{
            // 有默认视频，判断默认视频是否配置时间段
            if(this.roomInfo.param.stime1 !== '' || this.roomInfo.param.stime2 !== ''){
              // 判断时间段是否包含当前时间
              if(this.isDuringDate(Number(this.roomInfo.param.stime1),Number(this.roomInfo.param.etime1)) || this.isDuringDate(Number(this.roomInfo.param.stime2),Number(this.roomInfo.param.etime2))){
                // 包含当前时间段,显示默认视频
                this.$store.commit('home/set_controlNoLive',false);
              }else{
                // 不包含当前时间段,显示广告位
                this.$store.commit('home/set_controlNoLive',true);
              }
            }else{
              // 没有配置时间段，显示默认视频
              this.$store.commit('home/set_controlNoLive',false);
            }
          }
        }else{
          this.$store.commit('home/set_controlNoLive',false);
        }
      }
    },
    // 判断当前时间是否在某个时间段内
    isDuringDate (beginDateStr, endDateStr) {
      let curDate = new Date(),
      beginDate = new Date(beginDateStr),
      endDate = new Date(endDateStr);
      if (curDate >= beginDate && curDate <= endDate) {
        return true;
      }
      return false;
    },
    // 广告位跳转
    goLink(attr){
      if(attr){
        window.open(attr);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.NotLive{
  width: 1164px;
  height: 660px;
  background-color: #000;
  box-sizing: border-box;
  padding: 60px 0;
  position: relative;
  .smallLive{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .bannerImg{
      width: 750px;
      height: 344px;
    }
    p{
      color: #fff;
      margin: 30px 0;
    }
  }
  .codeBox{
    position: absolute;
    bottom: 10%;
    left: 20px;
    box-sizing: border-box;
    padding: 5px;
    background-color: #fa4440;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    .qrcode{
      width: 75px;
    }
    span{
      font-size: 12px;
      color: #fff;
      margin-top: 5px;
    }
    span:nth-child(3){
      margin-top: 0;
      margin-bottom: 5px;
    }
    .roundCloser{
      position: absolute;
      top: -10px;
      right: -28px;
      cursor: pointer;
    }
  }
}
.NotLive_other{
  width: 100%;
  height: 755px;
  padding: 80px 0;
  p{
    margin: 50px 0;
  }
}
</style>