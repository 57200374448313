<template>
  <div class="box">
    <img class="controlSpeak" @click="controlSpeak" src="../assets/images/user-info-close.png" alt="">
    <div class="content">
      <h3>禁言规则</h3>
      <p>红狮课堂直播间禁言与屏蔽规则如下：</p>
      <div>
        1. 禁止任何广告、链接、提及其他串流媒体和比较<br>
        2. 禁止无意义刷屏、过长的内文、未授权内容<br>
        3. 禁止分享或暗示个人的敏感、隐私信息（账号、手机号、电邮、群名）<br>
        4. 禁止散布违反法律、扰乱秩序的言论或谣言<br>
        5. 禁止辱骂主播、其他听众<br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'noSpeak',
  methods:{
    controlSpeak(){
      this.$store.commit('home/set_noSpeak',false);
    },
  }
}
</script>

<style lang="less" scoped>
  .box{
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px 0px;
    box-sizing: border-box;
    padding-left: 40px;
    .controlSpeak{
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    .content{
      h3{
        text-align: center;
        color: #000000;
      }
      p{
        color: #000000;
        margin-bottom: 20px;
      }
      div{
        line-height: 30px;
        color: #737373;
        padding-left: 30px;
      }
    }
  }
</style>