<template>
  <div class="liveList">
    <div ref="private-chat-con" class="scroll-item private-chat-con">
      <template v-if="privateChatHistoryList.length">
        <div v-for="item in privateChatHistoryList" :key="item.id">
          <!-- 用户消息展示 -->
          <!-- <div class="private-chat-item private-chat-item-left">
            <div class="private-chat-item-user">
              <span class="item-user-name">{{ privateUserInfo.nick }}</span><span class="item-user-time">{{ handleTime(item.ut) }}</span>
            </div>
            <div class="private-chat-content">
              {{ item.param.TXT }}
            </div>
          </div> -->
          <!-- 自己消息展示 -->
          <div v-if="item.sid === userInfo.id" class="private-chat-item private-chat-item-right">
            <div class="private-chat-item-user">
              <span class="item-user-time">{{ handleTime(item.ut) }}</span>
              <span class="item-user-name">{{ userInfo.nick || userInfo.whoami }}</span>
            </div>
            <div class="private-chat-content">
              {{ item.param.TXT }}
            </div>
          </div>
        </div>
      </template>
      <div v-else class="no-data">暂无聊天记录</div>
    </div>
    <div class="private-chat-send">
      <!-- 输入区域 -->
      <div class="send-input">
        <textarea v-model="sendTxt" @keyup.enter="handlePrivateMessageSend" @change="handleInputChange" />
      </div>
      <!-- 发送按钮 -->
      <div class="send-btn">
        <button @click.stop="handlePrivateMessageSend">发送</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name:'liveList',
  data(){
    return{
      sendTxt: ''
    }
  },
  computed:{
    userInfo() {
      return this.$store.state.login.userInfo
    },
    roomInfo() {
      return this.$store.state.home.roomInfo
    },
    socket() {
      return this.$store.state.home.socket
    },
    // 默认私聊用户
    privateUserInfo() {
      return this.$store.state.home.privateUserInfo
    },
    // 分配的客服信息
    distributecustomer(){
      return this.$store.state.home.distributecustomer
    },
    // 获取到的私聊聊天记录
    privateChatHistoryList(){
      return this.$store.state.home.privateChatHistoryList
    }
  },
  created() {
    this.comInit();
    console.log(this.privateChatHistoryList);
  },
  methods:{
    async comInit() {
      // 判断当前有没有缓存用户信息和房间信息
      if (this.userInfo.id && this.roomInfo.id) {
        let option = {
          broomid:this.roomInfo.id,
          id:this.userInfo.id,
          platid:1,
        }
        // 获取分配的客服
        await this.$store.dispatch('home/getDistributecustomer', option)
        // 请求和选中私聊用户的聊天记录
        if(this.distributecustomer.id){
          const params = {
            broomid: this.roomInfo.id,
            type: 11,
            id1: this.userInfo.id,
            id2: this.distributecustomer.id,
            page: 0,
            size: 50,
          }
          await this.$store.dispatch('home/getUserPrivateChatHistoryListById', params)
        }
      }
    },
    // 时间处理
    handleTime(time) {
      if (new Date(Number(time)).toDateString() === new Date().toDateString()) {
        return moment(time).format('HH:mm:ss')
      } else if (new Date(Number(time)) < new Date()){
        return moment(time).format('MM-DD HH:mm:ss')
      }
    },
    // 私聊消息发送
    handlePrivateMessageSend() {
      if (this.sendTxt.replace(/^\s*/, '')) {
        const nick = String(this.userInfo.mtaccr).replace(String(this.userInfo.mtaccr).substring(2, String(this.userInfo.mtaccr).length - 2), '****')
        const option = {
          broomid: this.roomInfo.id,
          sname: this.userInfo.nick || nick,
          sid: this.userInfo.id,
          rid: this.privateUserInfo.id,
          rname: this.privateUserInfo.nick,
          type: 11,
          param: {
            TXT: this.sendTxt
          }
        }
        this.socket.send(JSON.stringify(option))
        this.sendTxt = ''
      } else {
        this.$message({
          type: 'error',
          message: '请输入内容后在发送'
        })
      }
    },
    handleInputChange(event) {},
  }
}
</script>

<style lang="less" scoped>
  .liveList{
    width: 100%;
    .private-chat-con {
      height: 618px;
      padding: 10px;
      overflow-x: hidden;
      overflow-y: auto;
      .private-chat-item {
        margin-bottom: 8px;
        .private-chat-item-user {
          font-size: 12px;
          .item-user-name {
            color: #3498db;
            margin-right: 5px;
          }
          .item-user-time {
            color: #464852;
          }
        }
        .private-chat-content {
          text-align: left;
          font-size: 12px;
          color: #464852;
          padding: 0 10px;
          max-width: 320px;
        }
      }
      .private-chat-item-right {
        overflow: hidden;
        .private-chat-item-user {
          text-align: right;
          .item-user-name {
            margin-left: 5px;
          }
        }
        .private-chat-content {
          float: right;
        }
      }
      .no-data {
        font-size: 12px;
        text-align: center;
        color: #9aabb8;
      }
    }
    .private-chat-send {
      height: 120px;
      border-top: 1px solid #dedede;
      position: relative;
      .send-input {
        height: 85px;
        textarea {
          padding: 5px 10px;
          width: 100%;
          height: 100%;
          resize: none;
          box-sizing: border-box;
          border: none;
          outline: none;
        }
      }
      .send-btn {
        text-align: right;
        padding: 0 10px;
        button {
          width: 80px;
          height: 30px;
          color: #ffffff;
          font-size: 12px;
          background: #3498db;
        }
      }
    }
  }
</style>