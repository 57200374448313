<template>
  <div class="box scroll-item">
    <div class="MenuBox">
      <!-- <img @click="backIndex" class="logoImg" src="../assets/images/craig/slider/logo.png" alt=""> -->
      <div class="logo">
        <img src="../assets/images/craig/logo.png" />
        <span>红狮课堂</span>
      </div>
      <!-- <label for=""></label> -->
      <ul>
        <li @click="jump(1)">
          <img src="../assets/images/craig/slider/m_icon_1.png" alt="">
          <img src="../assets/images/craig/slider/m_icon_1_a.png" alt="">
          <span>关于红狮</span>
        </li>
        <li @click="jump(2)">
          <img src="../assets/images/craig/slider/m_icon_2.png" alt="">
          <img src="../assets/images/craig/slider/m_icon_2_a.png" alt="">
          <span>下载APP</span>
        </li>
        <li @click="jump(3)">
          <img src="../assets/images/craig/slider/m_icon_3.png" alt="">
          <img src="../assets/images/craig/slider/m_icon_3_a.png" alt="">
          <span>激活账户</span>
        </li>
      </ul>
      <!-- <label for=""></label> -->
      <ul>
        <li @click="jump(4)">
          <img src="../assets/images/craig/slider/m_icon_4.png" alt="">
          <img src="../assets/images/craig/slider/m_icon_4_a.png" alt="">
          <span>房间列表</span>
        </li>
        <li @click="jump(5)">
          <img src="../assets/images/craig/slider/m_icon_5.png" alt="">
          <img src="../assets/images/craig/slider/m_icon_5_a.png" alt="">
          <span>在线客服</span>
        </li>
      </ul>
      <!-- <label for=""></label> -->
      <ul>
        <li @click="jump(6)">
          <img src="../assets/images/craig/slider/m_icon_6.png" alt="">
          <img src="../assets/images/craig/slider/m_icon_6_a.png" alt="">
          <span>财经日历</span>
        </li>
        <li @click="jump(7)">
          <img src="../assets/images/craig/slider/m_icon_7.png" alt="">
          <img src="../assets/images/craig/slider/m_icon_7_a.png" alt="">
          <span>热点新闻</span>
        </li>
        <li @click="jump(8)">
          <img src="../assets/images/craig/slider/m_icon_8.png" alt="">
          <img src="../assets/images/craig/slider/m_icon_8_a.png" alt="">
          <span>市场快讯</span>
        </li>
        <li @click="jump(9)">
          <img src="../assets/images/craig/slider/m_icon_9.png" alt="">
          <img src="../assets/images/craig/slider/m_icon_9_a.png" alt="">
          <span>专家评论</span>
        </li>
      </ul>
      <div class="switchList">
        <swiper ref="mySwiper" :options="swiperOption">
          <div class="swiper-slide" v-for="(item, i) in swiperData" :key="i">
            <img :src="item.Url" />
          </div>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <div class="policy">
      <span class="stopButton" @click="jump(11)">禁言规则</span>
      <p>
        <span @click="jump(10)">隐私条款</span> &nbsp; | &nbsp; <span @click="jump(12)">免责声明</span>
      </p>
      <li>分析师建议仅供参考，不代表公司立场</li>
    </div>
    <img @click="controlSlider" class="packupImg" src="../assets/images/craig/slider/packup_n.png" alt="">
  </div>
</template>

<script>
import { getSwiperList } from "../api/home"
export default {
  name:'rightMenu',
  data(){
    return{
      // swiper 参数
      swiperOption: {
        slidesPerView: 1,
        // swiper图片之间的间隔
        spaceBetween: 0,
        centeredSlides: true,
        grabCursor: true,
        loop: true,
        preventClicks: true,
        //自动播放
        autoplay: {
          delay: 1000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          observerUpdate: function() {
            alert('监测到DOM变化，更新Swiper')
          }
        }
      },
      swiperData: []
    }
  },
  mounted(){
    this.getSwiperList()
  },
  computed:{
    timeTable(){
      return this.$store.state.home.timeTable
    },
    showRoomList(){
      return this.$store.state.home.showRoomList
    },
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  methods:{
    getSwiperList() {
      getSwiperList().then(res => {
        if (res.Status === 0) {
          this.swiper.params.autoplay.delay = Number(res.data.space)*1000
          // this.$refs.mySwiper.params.autoplay.delay = Number(res.data.space)*1000
          this.swiperData = res.data.list.map((item, index) => {
            return {
              Url: item
            }
          })
        }
      })
    },
    jump(attr){
      switch(attr){
        case 1:
          window.open('https://www.rlcproltd.net/#/Aboutus');
          break
        case 2:
          window.open('https://www.rlcproltd.net/#/Appdownload?key=1');
          break
        case 3:
          window.open('https://www.rlcproltd.net/uc/login');
          break
        case 4:
          // this.$store.commit('home/set_timeTable',true);
          this.$store.commit('home/set_noSpeak',false);
          this.$store.commit('home/set_showroomlist',!this.showRoomList);
          break
        case 5:
          window.open(sessionStorage.getItem('liveUC'));
          break
        case 6:
          window.open('https://www.rlcproltd.net/#/Market?key=1');
          break
        case 7:
          window.open('https://www.rlcproltd.net/#/Market?key=2');
          break
        case 8:
          window.open('https://www.rlcproltd.net/#/Market?key=3');
          break
        case 9:
          window.open('https://www.rlcproltd.net/#/Market?key=4');
          break
        case 10:
          window.open('https://www.rlcproltd.net/#/Privacy');
          break
        case 11:
          this.$store.commit('home/set_noSpeak',true);
          this.$store.commit('home/set_timeTable',false);
          break
        case 12:
          window.open('https://www.rlcproltd.net/#/Disclaimers');
          break
      }
    },
    // 控制侧边栏的显示与隐藏
    controlSlider(){
      this.$store.commit('home/set_packOrDevelop',true);
    },
    // 返回首页
    backIndex(){
      window.location.href = window.location.origin;
    },
  }
}
</script>

<style lang="less" scoped>
  .switchList {
    width: 255px;
    padding: 10px;
  }
  .swiper-slide {
    img {
      width: 100%;
      height: 160px;
    }
  }
  .scroll-item::-webkit-scrollbar{
    width: 2px;
  }
  .scroll-item::-webkit-scrollbar-thumb {
    background-color: #8c8ea7;
    border-radius: 2em;
  }
  .scroll-item::-webkit-scrollbar-track {
    background-color: #b6bfc2;
    border-radius: 2em;
  }
  .box{
    width: 275px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    // background-color: #1d203f;
    background-image: url('../assets/images/craig/menu_bj.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    box-sizing: border-box;
    padding-top: 20px;
    .MenuBox{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logoImg{
        margin-bottom: 5px;
      }
      .logo {
        display: flex;
        font-size: 24px;
        color: #5D7285;
        align-items: center;
        img {
          margin-right: 7px;
        }
      }
      ul{
        width: 100%;
        li{
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 48px;
          font-size: 16px;
          cursor: pointer;
          margin-top: 23px;
          img{
            margin-right: 19px;
            &:nth-child(2) {
              display: none;
            }
          }
          span{
            color: #5D7285;
            font-size: 16px;
          }
        }
        li:hover{
          background-color: #E9F5FE;
          span {
            color: #0C7FDA;
          }
          img {
            &:nth-child(1) {
              display: none;
            }
            &:nth-child(2) {
              display: inline-block;
            }
          }
        }
      }
      label{
        width: 80%;
        height: 1px;
        background-color: #3c3f52;
        margin: 20px auto;
      }
    }
    .policy{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-top: 113px;
      margin-top: 30px;
      .stopButton{
        width: 89px;
        height: 23px;
        border: 1px solid #8c8ea7;
        border-radius: 5px;
        color: #cfd5d5;
        font-size: 14px;
        text-align: center;
        line-height: 25px;
        cursor: pointer;
      }
      p{
        margin: 10px auto;
        span{
          color: #c6cacd;
          cursor: pointer;
          font-size: 14px;
        }
      }
      li{
        list-style: none;
        color: #c6cacd;
        font-size: 13px;
      }
    }
    .packupImg{
      position: absolute;
      top: 45%;
      right: -35px;
      transform: translate(-50%,0);
      cursor: pointer;
    }
  }
</style>